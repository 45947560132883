/**
 * @generated SignedSource<<5a562f7b7d9eefaa9b4be4c86967ecf0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CoryatEpisodeSelectQuery$variables = {};
export type CoryatEpisodeSelectQueryVariables = CoryatEpisodeSelectQuery$variables;
export type CoryatEpisodeSelectQuery$data = {
  readonly jeopardyLatestEpisodes: ReadonlyArray<{
    readonly id: string;
    readonly episodeNumber: number;
    readonly airDate: any;
    readonly participants: string;
    readonly season: string;
  } | null> | null;
  readonly jeopardyCoryatAttempts: ReadonlyArray<{
    readonly id: string;
    readonly jeopardyEpisode: {
      readonly id: string;
      readonly episodeNumber: number;
      readonly airDate: any;
      readonly participants: string;
      readonly season: string;
    };
    readonly live: boolean;
  } | null> | null;
};
export type CoryatEpisodeSelectQueryResponse = CoryatEpisodeSelectQuery$data;
export type CoryatEpisodeSelectQuery = {
  variables: CoryatEpisodeSelectQueryVariables;
  response: CoryatEpisodeSelectQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "episodeNumber",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "airDate",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "participants",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "season",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "JeopardyEpisodeType",
    "kind": "LinkedField",
    "name": "jeopardyLatestEpisodes",
    "plural": true,
    "selections": (v1/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "completed",
        "value": false
      }
    ],
    "concreteType": "JeopardyCoryatAttemptType",
    "kind": "LinkedField",
    "name": "jeopardyCoryatAttempts",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "JeopardyEpisodeType",
        "kind": "LinkedField",
        "name": "jeopardyEpisode",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "live",
        "storageKey": null
      }
    ],
    "storageKey": "jeopardyCoryatAttempts(completed:false)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CoryatEpisodeSelectQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CoryatEpisodeSelectQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c4d857b8edfd220c8badf659c94a2165",
    "id": null,
    "metadata": {},
    "name": "CoryatEpisodeSelectQuery",
    "operationKind": "query",
    "text": "query CoryatEpisodeSelectQuery {\n  jeopardyLatestEpisodes {\n    id\n    episodeNumber\n    airDate\n    participants\n    season\n  }\n  jeopardyCoryatAttempts(completed: false) {\n    id\n    jeopardyEpisode {\n      id\n      episodeNumber\n      airDate\n      participants\n      season\n    }\n    live\n  }\n}\n"
  }
};
})();

(node as any).hash = "b7487fbe55795fa01ae2c167959bbf0b";

export default node;
