/**
 * @generated SignedSource<<0836eb2e532936679ba79293fcfbe44c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SkipCategoryMetaCategorizationInput = {
  categoryId: string;
  clientMutationId?: string | null;
};
export type JeopardyCategorizationCluesListSkipCategoryMutation$variables = {
  input: SkipCategoryMetaCategorizationInput;
};
export type JeopardyCategorizationCluesListSkipCategoryMutationVariables = JeopardyCategorizationCluesListSkipCategoryMutation$variables;
export type JeopardyCategorizationCluesListSkipCategoryMutation$data = {
  readonly skipCategoryMetaCategorization: {
    readonly success: boolean | null;
  } | null;
};
export type JeopardyCategorizationCluesListSkipCategoryMutationResponse = JeopardyCategorizationCluesListSkipCategoryMutation$data;
export type JeopardyCategorizationCluesListSkipCategoryMutation = {
  variables: JeopardyCategorizationCluesListSkipCategoryMutationVariables;
  response: JeopardyCategorizationCluesListSkipCategoryMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SkipCategoryMetaCategorizationPayload",
    "kind": "LinkedField",
    "name": "skipCategoryMetaCategorization",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JeopardyCategorizationCluesListSkipCategoryMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JeopardyCategorizationCluesListSkipCategoryMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7bc04e3aaa3da9474c38ddfe219ba738",
    "id": null,
    "metadata": {},
    "name": "JeopardyCategorizationCluesListSkipCategoryMutation",
    "operationKind": "mutation",
    "text": "mutation JeopardyCategorizationCluesListSkipCategoryMutation(\n  $input: SkipCategoryMetaCategorizationInput!\n) {\n  skipCategoryMetaCategorization(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "b31ce0b36a7c2bf4773deb8bd28d83e0";

export default node;
