import React from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import "./SelectEpisode.css";

import { SelectEpisodeQuery } from "./__generated__/SelectEpisodeQuery.graphql";

const GET_EPISODE_OPTIONS = graphql`
  query SelectEpisodeQuery {
    jeopardyEpisodeOptions {
      id

      airDate

      finalJeopardyCategory {
        id
        comments
        category {
          name
        }
      }

      jeopardyepisodecategorySet {
        edges {
          node {
            id
            comments

            roundType {
              title
            }

            category {
              name
            }
          }
        }
      }

      metaCategories {
        name
        parent {
          name
        }
      }
    }
  }
`;

export default function SelectEpisode(props: {
  createGameWithEpisode: (episodeID: string, doubleJeopardy: boolean) => void;
}) {
  const data = useLazyLoadQuery<SelectEpisodeQuery>(
    GET_EPISODE_OPTIONS,
    {},
    {}
  );

  function selectSet(e: any, episodeID: string, doubleJeopardy: boolean) {
    e.preventDefault();

    props.createGameWithEpisode(episodeID, doubleJeopardy);
  }

  return (
    <ul>
      {data.jeopardyEpisodeOptions?.map((ep) => {
        return (
          <li>
            <div>
              <strong>{ep?.airDate}</strong>
              <div className="select-episode-category-tags">
                {ep!.metaCategories!.length > 0 ? <br /> : null}
                {ep!.metaCategories?.map((metaCategory) => {
                  return (
                    <div className="episode-category-tag">
                      {metaCategory!.name}
                    </div>
                  );
                })}
              </div>
              <p style={{ textDecoration: "underline" }}>
                Jeopardy!{" "}
                <button
                  onClick={(e) => {
                    selectSet(e, ep!.id, false);
                  }}
                >
                  Select Set
                </button>
              </p>
              {ep?.jeopardyepisodecategorySet.edges
                .filter((jecNode) => {
                  return jecNode?.node?.roundType.title === "Jeopardy!";
                })
                .map((jecNode) => {
                  const jec = jecNode?.node;

                  return <span>{jec?.category?.name} --- </span>;
                })}

              <p style={{ textDecoration: "underline" }}>
                Double Jeopardy!{" "}
                <button
                  onClick={(e) => {
                    selectSet(e, ep!.id, true);
                  }}
                >
                  Select Set
                </button>
              </p>
              {ep?.jeopardyepisodecategorySet.edges
                .filter((jecNode) => {
                  return jecNode?.node?.roundType.title === "Double Jeopardy!";
                })
                .map((jecNode) => {
                  const jec = jecNode?.node;

                  return <span>{jec?.category?.name} --- </span>;
                })}
              <p style={{ textDecoration: "underline" }}>Final Jeopardy! </p>
              <span>{ep?.finalJeopardyCategory?.category?.name}</span>
              <br />
              <hr />
            </div>
          </li>
        );
      })}
    </ul>
  );
}
