import React from "react";
import "./Flashcard.css";
import StudyClue from "./StudyClue";

import { graphql, useMutation } from "react-relay";

import { FlashcardLogResponseMutation } from "./__generated__/FlashcardLogResponseMutation.graphql";

interface IProps {
  clue: any;
  incrementClue: any;
  showClue: boolean;
  toggleClue: any;
  loggedIn: boolean;
}

const LOG_RESPONSE = graphql`
  mutation FlashcardLogResponseMutation($input: LogFlashcardResponseInput!) {
    logFlashcardResponse(input: $input) {
      success
    }
  }
`;

export default function Flashcard({
  clue,
  incrementClue,
  showClue,
  toggleClue,
  loggedIn,
}: IProps) {
  const [logResponse] = useMutation<FlashcardLogResponseMutation>(LOG_RESPONSE);

  function processCard(correct: boolean) {
    logResponse({
      variables: {
        input: {
          clueId: clue.id,
          correct: correct,
        },
      },
    });

    incrementClue();
  }

  return (
    <div className="jeopardy-flashcard">
      <StudyClue
        categoryName={clue.episodeCategory.category.name!}
        categoryClue={clue}
        showClue={showClue}
        toggleClue={toggleClue}
      />
      {loggedIn ? (
        <div className="jeopardy-flashcard-responses">
          <button
            className="jeopardy-flashcard-response"
            style={{ backgroundColor: "green" }}
            onClick={(e) => {
              e.preventDefault();
              processCard(true);
            }}
          />
          <button
            className="jeopardy-flashcard-response"
            style={{ backgroundColor: "darkred" }}
            onClick={(e) => {
              e.preventDefault();
              processCard(false);
            }}
          />{" "}
        </div>
      ) : (
        <button
          onClick={(e) => {
            e.preventDefault();
            incrementClue();
          }}
        >
          Next Clue
        </button>
      )}
    </div>
  );
}
