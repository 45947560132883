import React from "react";
import "./ClueDisplay.css";
import { useLazyLoadQuery, graphql } from "react-relay";

const GET_CLUE_TEXT = graphql`
  query ClueDisplayQuery($playerName: String!, $gameCode: String!) {
    triviaGameClueTextForPlayer(playerName: $playerName, gameCode: $gameCode) {
      displayClue
      realClue
      clueSwapped
      blackedOut
    }
  }
`;

export default function ClueDisplay({ playerName, gameCode }) {
  const data = useLazyLoadQuery(
    GET_CLUE_TEXT,
    {
      playerName: playerName,
      gameCode: gameCode,
    },
    {
      fetchPolicy: "network-only",
    }
  );

  return (
    <p
      dangerouslySetInnerHTML={{
        __html: data.triviaGameClueTextForPlayer.displayClue,
      }}
    />
  );
}
