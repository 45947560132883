import React, { useEffect, useState } from "react";
import "./ClueBuzzer.css";

export default function ClueBuzzer(props: {
  buzzerActive: boolean;
  buzzIn: () => void;
  buzzerPermDisabled: boolean;
  turnOffBuzzerOnBuzz?: boolean;
}) {
  const [buzzerDisabled, setBuzzerDisabled] = useState(false);

  const buzzerActiveRef = React.useRef(props);

  const setBuzzerActiveRef = (data: {
    buzzerActive: boolean;
    buzzIn: () => void;
    buzzerPermDisabled: boolean;
  }) => {
    buzzerActiveRef.current = data;
  };

  useEffect(() => {
    setBuzzerActiveRef(props);
  }, [props]);

  useEffect(() => {
    if (props.buzzerPermDisabled) {
      setBuzzerDisabled(true);
    }
  }, [props.buzzerPermDisabled]);

  function handleBuzzerClick(e: { preventDefault: () => void }) {
    e.preventDefault();
    clickBuzzer();
  }

  function handleBuzzerKey(event: { code: string; repeat: boolean }) {
    if (event.repeat) {
      return;
    }

    if (event.code === "Space") {
      clickBuzzer();
    }
  }

  useEffect(() => {
    // event = keyup or keydown
    document.addEventListener("keydown", handleBuzzerKey);
  }, []);

  function clickBuzzer() {
    if (buzzerActiveRef.current.buzzerPermDisabled) {
      return;
    }

    if (buzzerActiveRef.current.buzzerActive) {
      props.buzzIn();

      document.removeEventListener("keydown", handleBuzzerKey);

      if (props.turnOffBuzzerOnBuzz) {
        setBuzzerDisabled(true);
      }
    } else {
      setBuzzerDisabled(true);

      setTimeout(() => {
        setBuzzerDisabled(false);
      }, 250);
    }
  }

  return (
    <button
      className="jeopardy-buzzer"
      onClick={handleBuzzerClick}
      disabled={buzzerDisabled}
    >
      Buzzer
    </button>
  );
}
