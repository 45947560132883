/**
 * @generated SignedSource<<6ebd1a5893e50e6f4b2d46af7df611c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type JeopardySessionValidateParamsInput = {
  gameCode: string;
  playerName: string;
  clientMutationId?: string | null;
};
export type JeopardyGamePageValidateParamsMutation$variables = {
  input: JeopardySessionValidateParamsInput;
};
export type JeopardyGamePageValidateParamsMutationVariables = JeopardyGamePageValidateParamsMutation$variables;
export type JeopardyGamePageValidateParamsMutation$data = {
  readonly jeopardyGameSessionCheckValidParams: {
    readonly success: boolean | null;
  } | null;
};
export type JeopardyGamePageValidateParamsMutationResponse = JeopardyGamePageValidateParamsMutation$data;
export type JeopardyGamePageValidateParamsMutation = {
  variables: JeopardyGamePageValidateParamsMutationVariables;
  response: JeopardyGamePageValidateParamsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "JeopardySessionValidateParamsPayload",
    "kind": "LinkedField",
    "name": "jeopardyGameSessionCheckValidParams",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JeopardyGamePageValidateParamsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JeopardyGamePageValidateParamsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "08b916e65d98d266b7972e0bb174a506",
    "id": null,
    "metadata": {},
    "name": "JeopardyGamePageValidateParamsMutation",
    "operationKind": "mutation",
    "text": "mutation JeopardyGamePageValidateParamsMutation(\n  $input: JeopardySessionValidateParamsInput!\n) {\n  jeopardyGameSessionCheckValidParams(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "deeb511e76ea131fee3de4c59ca3137e";

export default node;
