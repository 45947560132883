/**
 * @generated SignedSource<<62ec6cc56df83f8e35bb18a10e4d601a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type StudySetQuery$variables = {};
export type StudySetQueryVariables = StudySetQuery$variables;
export type StudySetQuery$data = {
  readonly jeopardyStudySet: ReadonlyArray<{
    readonly id: string;
    readonly text: string | null;
    readonly correctResponse: string | null;
    readonly answer: {
      readonly correctResponse: string;
      readonly timesSeen: number | null;
    } | null;
    readonly value: number | null;
    readonly episodeCategory: {
      readonly category: {
        readonly name: string;
      } | null;
      readonly episode: {
        readonly airDate: any;
      };
      readonly comments: string | null;
    };
  } | null> | null;
  readonly whoAmI: {
    readonly username: string;
  } | null;
};
export type StudySetQueryResponse = StudySetQuery$data;
export type StudySetQuery = {
  variables: StudySetQueryVariables;
  response: StudySetQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correctResponse",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timesSeen",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "airDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comments",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StudySetQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JeopardyClueType",
        "kind": "LinkedField",
        "name": "jeopardyStudySet",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyClueAnswerType",
            "kind": "LinkedField",
            "name": "answer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyEpisodeCategoryType",
            "kind": "LinkedField",
            "name": "episodeCategory",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyCategoryType",
                "kind": "LinkedField",
                "name": "category",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyEpisodeType",
                "kind": "LinkedField",
                "name": "episode",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "whoAmI",
        "plural": false,
        "selections": [
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StudySetQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JeopardyClueType",
        "kind": "LinkedField",
        "name": "jeopardyStudySet",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyClueAnswerType",
            "kind": "LinkedField",
            "name": "answer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyEpisodeCategoryType",
            "kind": "LinkedField",
            "name": "episodeCategory",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyCategoryType",
                "kind": "LinkedField",
                "name": "category",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyEpisodeType",
                "kind": "LinkedField",
                "name": "episode",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "whoAmI",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ca6750f459720a8def67f98a5660becd",
    "id": null,
    "metadata": {},
    "name": "StudySetQuery",
    "operationKind": "query",
    "text": "query StudySetQuery {\n  jeopardyStudySet {\n    id\n    text\n    correctResponse\n    answer {\n      correctResponse\n      timesSeen\n      id\n    }\n    value\n    episodeCategory {\n      category {\n        name\n        id\n      }\n      episode {\n        airDate\n        id\n      }\n      comments\n      id\n    }\n  }\n  whoAmI {\n    username\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "42d81949ae0f617cc455ce44a47318f6";

export default node;
