/**
 * @generated SignedSource<<a82a65e9568942e6f3f594b59d326fd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type JeopardyCategorizationCluesListQuery$variables = {
  searchText?: string | null;
};
export type JeopardyCategorizationCluesListQueryVariables = JeopardyCategorizationCluesListQuery$variables;
export type JeopardyCategorizationCluesListQuery$data = {
  readonly jeopardyCluesForMetaCategorization: ReadonlyArray<{
    readonly id: string;
    readonly text: string | null;
    readonly answer: {
      readonly id: string;
      readonly correctResponse: string;
    } | null;
    readonly episodeCategory: {
      readonly category: {
        readonly id: string;
        readonly name: string;
      } | null;
    };
  } | null> | null;
};
export type JeopardyCategorizationCluesListQueryResponse = JeopardyCategorizationCluesListQuery$data;
export type JeopardyCategorizationCluesListQuery = {
  variables: JeopardyCategorizationCluesListQueryVariables;
  response: JeopardyCategorizationCluesListQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchText"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "searchText",
    "variableName": "searchText"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "JeopardyClueAnswerType",
  "kind": "LinkedField",
  "name": "answer",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "correctResponse",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "JeopardyCategoryType",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JeopardyCategorizationCluesListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JeopardyClueType",
        "kind": "LinkedField",
        "name": "jeopardyCluesForMetaCategorization",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyEpisodeCategoryType",
            "kind": "LinkedField",
            "name": "episodeCategory",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JeopardyCategorizationCluesListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JeopardyClueType",
        "kind": "LinkedField",
        "name": "jeopardyCluesForMetaCategorization",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyEpisodeCategoryType",
            "kind": "LinkedField",
            "name": "episodeCategory",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5f5c2449d1bd880d8524491fd708b155",
    "id": null,
    "metadata": {},
    "name": "JeopardyCategorizationCluesListQuery",
    "operationKind": "query",
    "text": "query JeopardyCategorizationCluesListQuery(\n  $searchText: String\n) {\n  jeopardyCluesForMetaCategorization(searchText: $searchText) {\n    id\n    text\n    answer {\n      id\n      correctResponse\n    }\n    episodeCategory {\n      category {\n        id\n        name\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f451fe2f0a87d16a1e2eadcab69b8d69";

export default node;
