import React, { SetStateAction, useEffect, useState } from "react";
import type { TriviaGameBoardQuery } from "./__generated__/TriviaGameBoardQuery.graphql";
import "./JeopardyBoard.css";
import useWebSocket, { ReadyState } from "react-use-websocket";
// @ts-ignore
import ScaleText from "react-scale-text";

import { graphql, useLazyLoadQuery } from "react-relay";
import ClueDisplay from "./ClueDisplay";
import TargetSelector from "./TargetSelector";
import TriviaLeaderboard from "./TriviaLeaderboard";

const TRIVIA_GAME_QUERY = graphql`
  query TriviaGameBoardQuery($gameCode: String!, $playerName: String!) {
    triviaGameSession(gameCode: $gameCode) {
      admin {
        username
      }

      triviagamesessionplayerSet {
        edges {
          node {
            name
            active
            points
            admin
          }
        }
      }

      currentClue {
        text
        episodeCategory {
          category {
            name
          }

          comments
        }
        answer {
          correctResponse
        }
      }

      currentStage
    }

    triviaGamePlayerInventory(gameCode: $gameCode, playerName: $playerName) {
      item1 {
        key
        displayName
        targetGlobal
        targetOther
        targetSelf
        stage
      }
      item2 {
        key
        displayName
        targetGlobal
        targetOther
        targetSelf
        stage
      }
    }

    whoAmI {
      username
    }
  }
`;

export default function TriviaGameBoard(props: {
  gameCode: string;
  playerName: string;
}) {
  const [reloads, setReloads] = useState<number>(0);

  const data = useLazyLoadQuery<TriviaGameBoardQuery>(
    TRIVIA_GAME_QUERY,
    { gameCode: props.gameCode, playerName: props.playerName },
    {
      fetchKey: reloads,
      fetchPolicy: "network-only",
    }
  );

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `wss://bobbygould.com/ws/trivia_game/${props.gameCode}/`,
    {
      shouldReconnect: (closeEvent) => true,
    }
  );

  const [currentStage, setCurrentStage] = useState(
    data.triviaGameSession!.currentStage!
  );

  const [usedItemNums, setUsedItemNums] = useState<Array<number>>([]);
  const [showActiveAttacks, setShowActiveAttacks] = useState(false);
  const [showTargetSelectorForItem, setShowTargetSelectorForItem] = useState<
    undefined | number
  >(undefined);

  const [messageHistory, setMessageHistory] = useState([]);
  const [currentPlayers, setCurrentPlayers] = useState(
    data.triviaGameSession?.triviagamesessionplayerSet?.edges
      .filter((playerNode) => {
        return playerNode?.node?.active && !playerNode?.node?.admin;
      })
      .map((playerNode) => {
        return playerNode!.node!.name;
      })
  );

  const playerData = data.triviaGameSession?.triviagamesessionplayerSet?.edges;

  const playerInventory = data.triviaGamePlayerInventory!;
  const currentClue = data.triviaGameSession?.currentClue;

  const playerIsAdmin =
    data?.whoAmI?.username === data?.triviaGameSession?.admin.username;

  const [playersWithAnswers, setPlayersWithAnswers] = useState<Array<string>>(
    []
  );

  const [underPressure, setUnderPressure] = useState(false);

  const [activeItems, setActiveItems] = useState<Array<object>>([]);

  const [scores, setScores] = useState(
    Object.assign(
      {},
      ...playerData!.map((playerNode) => ({
        [playerNode!.node!.name]: playerNode!.node!.points,
      }))
    )
  );

  const [displayAnswer, setDisplayAnswer] = useState(false);
  const [userAnswer, setUserAnswer] = useState<string>("");

  function sendJSONMessage(eventType: string, message: string) {
    console.log("SENDING MESSAGE FOR EVENT: " + eventType, message);
    sendMessage(
      JSON.stringify({
        gameCode: props.gameCode,
        event: eventType,
        message: message,
      })
    );
  }

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  function appendItemActivation(itemActivationData: {
    attacker: string;
    target: string;
    itemKey: string;
    itemDisplayName: string;
  }) {
    if (
      !activeItems.some(
        (item) =>
          // @ts-ignore
          item.attacker === itemActivationData.attacker &&
          // @ts-ignore
          item.itemKey === itemActivationData.itemKey
      )
    ) {
      setActiveItems([itemActivationData, ...activeItems]);
    }
  }

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendJSONMessage("NEW_PLAYER", props.playerName);
    }
  }, [readyState]);

  useEffect(() => {
    if (lastMessage !== null) {
      // @ts-ignore
      setMessageHistory((prev) => prev.concat(lastMessage));
    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
    if (lastMessage !== null) {
      const msgData = JSON.parse(lastMessage.data);

      console.log("RECEIVED EVENT: " + msgData.event, msgData.message);

      if (msgData.event === "NEW_PLAYER") {
        if (!currentPlayers?.includes(msgData.message)) {
          setCurrentPlayers((prev) => prev?.concat(msgData.message));
        }
      } else if (msgData.event === "ANSWER_SUBMITTED_BY_NAME") {
        setPlayersWithAnswers([msgData.message, ...playersWithAnswers]);
      } else if (msgData.event === "PLAYER_LEAVE") {
        if (currentPlayers?.includes(msgData.message)) {
          setCurrentPlayers((prevState: string[] | undefined) => {
            return prevState?.filter((player) => player !== msgData.message);
          });
        }
      } else if (msgData.event === "POINTS_UPDATE") {
        const pointsData = JSON.parse(msgData.message);

        // @ts-ignore
        setScores((prevScores) => {
          prevScores[pointsData.player] = parseInt(pointsData.points);

          return { ...prevScores };
        });
      } else if (msgData.event === "NEW_STAGE") {
        const newStageData = JSON.parse(msgData.message);

        setCurrentStage(parseInt(newStageData.next_stage));

        if (newStageData.reset) {
          setReloads(reloads + 1);
          setUsedItemNums([]);
          setActiveItems([]);
          setPlayersWithAnswers([]);
          setShowActiveAttacks(false);
          setUserAnswer("");
          setShowTargetSelectorForItem(undefined);
          setUnderPressure(false);
        }
      } else if (msgData.event === "ITEM_ACTIVATED") {
        const itemData = JSON.parse(msgData.message);

        appendItemActivation(itemData);

        if (
          itemData.attacker === props.playerName &&
          itemData.itemKey === "VIEW_ATTACKS"
        ) {
          setShowActiveAttacks(true);
        }

        if (
          itemData.target === props.playerName &&
          itemData.itemKey === "PRESSURE"
        ) {
          setUnderPressure(true);
        }
      } else {
        console.log("Could not handle event.");
      }
    }
  }, [lastMessage]);

  function activateItem(itemNum: number) {
    setShowTargetSelectorForItem(itemNum);
  }

  function activateItemWithTarget(itemNum: number, player: string) {
    setUsedItemNums([itemNum, ...usedItemNums]);
    sendJSONMessage(
      "USE_ITEM",
      JSON.stringify({ itemNum: itemNum, target: player })
    );
  }

  function submitAnswer() {
    sendJSONMessage("SUBMIT_ANSWER", userAnswer);
  }

  function nextStage() {
    sendJSONMessage("NEXT_STAGE", "");
  }

  return (
    <>
      <div
        className={`jeopardy-status-connection jeopardy-status-${
          connectionStatus === "Open"
            ? "connected"
            : connectionStatus === "Closed"
            ? "disconnected"
            : "connecting"
        }`}
      >
        <strong>
          {connectionStatus === "Open"
            ? "Connected"
            : connectionStatus === "Closed"
            ? "Disconnected"
            : "Connecting..."}
        </strong>
      </div>
      <br />
      <br />

      {playerIsAdmin ? (
        <p>
          <button onClick={nextStage}>Next Stage</button>
        </p>
      ) : null}

      <div className="trivia-game-inventory">
        <h2>Inventory: ({activeItems.length} Activated Item(s))</h2>
        {showActiveAttacks ? (
          <div>
            <ul>
              {activeItems.map((item) => {
                return (
                  <li>
                    {/* @ts-ignore */}
                    {item.attacker} ({item.itemDisplayName}) {"---->"}{" "}
                    {/* @ts-ignore */}
                    {item.target}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
        <p>
          Item 1:{" "}
          <button
            disabled={
              // @ts-ignore
              usedItemNums.includes(1)
                ? true
                : playerInventory?.item1?.stage !== -1 &&
                  playerInventory?.item1?.stage !== currentStage
            }
            onClick={() => activateItem(1)}
          >
            {playerInventory?.item1?.displayName}
          </button>
          <br />
          Item 2:{" "}
          <button
            disabled={
              // @ts-ignore
              usedItemNums.includes(2)
                ? true
                : playerInventory?.item2?.stage !== -1 &&
                  playerInventory?.item2?.stage !== currentStage
            }
            onClick={() => activateItem(2)}
          >
            {playerInventory?.item2?.displayName}
          </button>
        </p>

        {showTargetSelectorForItem ? (
          <TargetSelector
            submitTarget={activateItemWithTarget}
            itemNum={showTargetSelectorForItem}
            players={currentPlayers}
          />
        ) : null}
      </div>

      <div className="jeopardy-board-players">
        {currentPlayers ? (
          <TriviaLeaderboard
            currentPlayers={currentPlayers}
            scores={scores}
            playersWithAnswers={playersWithAnswers}
          />
        ) : null}
      </div>

      <div className="trivia-game-clue">
        <h2>
          Category: {currentClue?.episodeCategory?.category?.name}{" "}
          {underPressure ? (
            <span style={{ color: "red" }}>UNDER PRESSURE</span>
          ) : null}
        </h2>
        {currentClue?.episodeCategory?.comments ? (
          <span>Category Comments: {currentClue.episodeCategory.comments}</span>
        ) : null}
        {currentStage >= 2 ? (
          <div>
            <ClueDisplay
              playerName={props.playerName}
              gameCode={props.gameCode}
            />
            <p>Correct Answer: {currentClue?.answer?.correctResponse}</p>
            <label>
              Response:{" "}
              <input
                type="text"
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
              />{" "}
              <button
                onClick={submitAnswer}
                disabled={playersWithAnswers.includes(props.playerName)}
              >
                Submit Answer
              </button>
            </label>
          </div>
        ) : null}

        {currentStage === 3 ? (
          <div>
            <p>
              Answer:{" "}
              <span
                dangerouslySetInnerHTML={{
                  __html: currentClue!.answer!.correctResponse,
                }}
              />
            </p>
            <p>
              You got it{" "}
              {currentClue?.answer?.correctResponse.toLowerCase() ===
              userAnswer.toLowerCase()
                ? "CORRECT!"
                : "INCORRECT."}
            </p>
          </div>
        ) : null}
      </div>
    </>
  );
}
