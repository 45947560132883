import React from "react";
import StudySet from "./StudySet";
import "./JeopardyStudyPage.css";
import KeywordSearch from "./KeywordSearch";

export default function JeopardyStudyPage() {
  return (
    <div className="jeopardy-study-page">
      <React.Suspense fallback={"Loading flashcards..."}>
        <StudySet />
      </React.Suspense>
      <KeywordSearch />
    </div>
  );
}
