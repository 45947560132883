/**
 * @generated SignedSource<<28774f6cb19f9d8678b4e9c05e591e91>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type JeopardyBoardQuery$variables = {
  gameCode: string;
};
export type JeopardyBoardQueryVariables = JeopardyBoardQuery$variables;
export type JeopardyBoardQuery$data = {
  readonly jeopardyGameSession: {
    readonly selectedClues: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null;
      } | null>;
    };
    readonly admin: {
      readonly username: string;
    };
    readonly jeopardygamesessionplayerSet: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly name: string;
          readonly active: boolean;
          readonly points: number;
        } | null;
      } | null>;
    };
    readonly jeopardyCategories: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly episode: {
            readonly jarchiveGameId: number;
          };
          readonly name: string;
          readonly category: {
            readonly name: string;
          } | null;
          readonly comments: string | null;
          readonly jeopardyclueSet: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly text: string | null;
                readonly answer: {
                  readonly correctResponse: string;
                } | null;
                readonly value: number | null;
                readonly dailyDouble: boolean;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
    readonly doubleJeopardyCategories: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly episode: {
            readonly jarchiveGameId: number;
          };
          readonly name: string;
          readonly category: {
            readonly name: string;
          } | null;
          readonly comments: string | null;
          readonly jeopardyclueSet: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly text: string | null;
                readonly answer: {
                  readonly correctResponse: string;
                } | null;
                readonly value: number | null;
                readonly dailyDouble: boolean;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
    readonly finalJeopardyClue: {
      readonly episodeCategory: {
        readonly category: {
          readonly name: string;
        } | null;
      };
      readonly text: string | null;
      readonly answer: {
        readonly correctResponse: string;
      } | null;
    };
  } | null;
  readonly whoAmI: {
    readonly username: string;
  } | null;
};
export type JeopardyBoardQueryResponse = JeopardyBoardQuery$data;
export type JeopardyBoardQuery = {
  variables: JeopardyBoardQueryVariables;
  response: JeopardyBoardQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gameCode"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "gameCode",
    "variableName": "gameCode"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "JeopardyClueTypeConnection",
  "kind": "LinkedField",
  "name": "selectedClues",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JeopardyClueTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "JeopardyClueType",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jarchiveGameId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "JeopardyCategoryType",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": [
    (v6/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comments",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correctResponse",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "JeopardyClueAnswerType",
  "kind": "LinkedField",
  "name": "answer",
  "plural": false,
  "selections": [
    (v13/*: any*/)
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dailyDouble",
  "storageKey": null
},
v17 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "JeopardyEpisodeCategoryTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JeopardyEpisodeCategoryType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyEpisodeType",
            "kind": "LinkedField",
            "name": "episode",
            "plural": false,
            "selections": [
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyClueTypeConnection",
            "kind": "LinkedField",
            "name": "jeopardyclueSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyClueTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JeopardyClueType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v12/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v18 = [
  (v4/*: any*/),
  (v2/*: any*/)
],
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "JeopardyCategoryType",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "JeopardyClueAnswerType",
  "kind": "LinkedField",
  "name": "answer",
  "plural": false,
  "selections": [
    (v13/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v21 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "JeopardyEpisodeCategoryTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JeopardyEpisodeCategoryType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyEpisodeType",
            "kind": "LinkedField",
            "name": "episode",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v19/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyClueTypeConnection",
            "kind": "LinkedField",
            "name": "jeopardyclueSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyClueTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JeopardyClueType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v12/*: any*/),
                      (v20/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JeopardyBoardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JeopardyGameSessionType",
        "kind": "LinkedField",
        "name": "jeopardyGameSession",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "admin",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyGameSessionPlayerTypeConnection",
            "kind": "LinkedField",
            "name": "jeopardygamesessionplayerSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyGameSessionPlayerTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JeopardyGameSessionPlayerType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyEpisodeCategoryTypeConnection",
            "kind": "LinkedField",
            "name": "jeopardyCategories",
            "plural": false,
            "selections": (v17/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyEpisodeCategoryTypeConnection",
            "kind": "LinkedField",
            "name": "doubleJeopardyCategories",
            "plural": false,
            "selections": (v17/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyClueType",
            "kind": "LinkedField",
            "name": "finalJeopardyClue",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyEpisodeCategoryType",
                "kind": "LinkedField",
                "name": "episodeCategory",
                "plural": false,
                "selections": [
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "whoAmI",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JeopardyBoardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JeopardyGameSessionType",
        "kind": "LinkedField",
        "name": "jeopardyGameSession",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "admin",
            "plural": false,
            "selections": (v18/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyGameSessionPlayerTypeConnection",
            "kind": "LinkedField",
            "name": "jeopardygamesessionplayerSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyGameSessionPlayerTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JeopardyGameSessionPlayerType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyEpisodeCategoryTypeConnection",
            "kind": "LinkedField",
            "name": "jeopardyCategories",
            "plural": false,
            "selections": (v21/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyEpisodeCategoryTypeConnection",
            "kind": "LinkedField",
            "name": "doubleJeopardyCategories",
            "plural": false,
            "selections": (v21/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyClueType",
            "kind": "LinkedField",
            "name": "finalJeopardyClue",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyEpisodeCategoryType",
                "kind": "LinkedField",
                "name": "episodeCategory",
                "plural": false,
                "selections": [
                  (v19/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/),
              (v20/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "whoAmI",
        "plural": false,
        "selections": (v18/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fcbf08ce647e5918697b75f0129ba570",
    "id": null,
    "metadata": {},
    "name": "JeopardyBoardQuery",
    "operationKind": "query",
    "text": "query JeopardyBoardQuery(\n  $gameCode: String!\n) {\n  jeopardyGameSession(gameCode: $gameCode) {\n    selectedClues {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n    admin {\n      username\n      id\n    }\n    jeopardygamesessionplayerSet {\n      edges {\n        node {\n          name\n          active\n          points\n          id\n        }\n      }\n    }\n    jeopardyCategories {\n      edges {\n        node {\n          episode {\n            jarchiveGameId\n            id\n          }\n          name\n          category {\n            name\n            id\n          }\n          comments\n          jeopardyclueSet {\n            edges {\n              node {\n                id\n                text\n                answer {\n                  correctResponse\n                  id\n                }\n                value\n                dailyDouble\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    doubleJeopardyCategories {\n      edges {\n        node {\n          episode {\n            jarchiveGameId\n            id\n          }\n          name\n          category {\n            name\n            id\n          }\n          comments\n          jeopardyclueSet {\n            edges {\n              node {\n                id\n                text\n                answer {\n                  correctResponse\n                  id\n                }\n                value\n                dailyDouble\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    finalJeopardyClue {\n      episodeCategory {\n        category {\n          name\n          id\n        }\n        id\n      }\n      text\n      answer {\n        correctResponse\n        id\n      }\n      id\n    }\n    id\n  }\n  whoAmI {\n    username\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0b368fa75c0da47c81de402a4e2e2962";

export default node;
