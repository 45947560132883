import React, { useState, useEffect } from "react";

import { graphql, useLazyLoadQuery, useMutation } from "react-relay";

import { CoryatCluesQuery } from "./__generated__/CoryatCluesQuery.graphql";
import { CoryatCluesLogResponseMutation } from "./__generated__/CoryatCluesLogResponseMutation.graphql";

import "./CoryatClues.css";
import ClueBuzzer from "../../JeopardyGamePage/JeopardyBoard/ClueDisplay/ClueBuzzer";
import ClueTimer from "../../JeopardyGamePage/JeopardyBoard/ClueDisplay/ClueTimer";

const GET_CLUES = graphql`
  query CoryatCluesQuery($episodeID: ID!, $attemptID: ID!) {
    jeopardyCluesForEpisode(episodeId: $episodeID) {
      id

      episodeCategory {
        category {
          name
        }

        roundType {
          title
        }
      }

      text
      value

      answer {
        correctResponse
      }
    }

    jeopardyCoryatAttempt(attemptId: $attemptID) {
      jeopardycluecoryatresponseSet {
        edges {
          node {
            clue {
              id
            }
          }
        }
      }

      live
    }
  }
`;

const LOG_RESPONSE = graphql`
  mutation CoryatCluesLogResponseMutation(
    $input: LogJeopardyCoryatResponseInput!
  ) {
    logJeopardyCoryatResponse(input: $input) {
      success
    }
  }
`;

export default function CoryatClues(props: {
  episodeID: string;
  attemptID: string;
}) {
  const [logCoryatResponse] =
    useMutation<CoryatCluesLogResponseMutation>(LOG_RESPONSE);

  const data = useLazyLoadQuery<CoryatCluesQuery>(
    GET_CLUES,
    { episodeID: props.episodeID, attemptID: props.attemptID },
    {}
  );

  const attemptIsLive = data.jeopardyCoryatAttempt!.live;

  // @ts-ignore
  const [loggedClueIDs, setLoggedClueIDs]: [[string] | [], any] = useState(
    data.jeopardyCoryatAttempt!.jeopardycluecoryatresponseSet.edges.map(
      (respNode) => {
        return respNode!.node!.clue.id;
      }
    )
  );

  const firstAttemptIndex = data.jeopardyCluesForEpisode?.findIndex((clue) => {
    // @ts-ignore
    return !loggedClueIDs.includes(clue!.id);
  });

  const [clueIndex, setClueIndex] = useState(
    firstAttemptIndex ? firstAttemptIndex : 0
  );

  const [buzzerActive, setBuzzerActive] = useState(true);
  const [playerBuzzed, setPlayerBuzzed] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [timerOverride, setTimerOverride] = useState(5 * 1000);

  const clueIndexRef = React.useRef(clueIndex);

  const setClueIndexRef = (newClueIndex: number) => {
    clueIndexRef.current = newClueIndex;

    setClueIndex(newClueIndex);
  };

  function decrementClueIndex() {
    if (clueIndexRef.current > 0) {
      setClueIndexRef(clueIndexRef.current - 1);
    }
  }

  function incrementClueIndex() {
    if (clueIndexRef.current < data.jeopardyCluesForEpisode!.length - 1) {
      if (
        data.jeopardyCluesForEpisode![clueIndexRef.current + 1]
          ?.episodeCategory!.roundType!.title === "Final Jeopardy!"
      ) {
        setTimerOverride(30 * 1000);
      }

      setClueIndexRef(clueIndexRef.current + 1);
    }
  }

  function logResponse(e: any, clueID: string, correct: boolean | null) {
    e.preventDefault();

    logCoryatResponse({
      variables: {
        input: { clueId: clueID, correct: correct, attemptId: props.attemptID },
      },
      onCompleted: () => {
        // @ts-ignore
        setLoggedClueIDs((currState) => {
          currState.push(clueID);

          return [...currState];
        });

        setPlayerBuzzed(false);
        setShowAnswer(false);
        setBuzzerActive(true);

        setTimerOverride((prevState) => {
          if (prevState > 5 * 1000) {
            return 5 * 1000;
          } else {
            return 5 * 1000 + 1;
          }
        });

        incrementClueIndex();
      },
    });
  }

  useEffect(() => {
    document.addEventListener("keydown", (event) => {
      if (event.code === "ArrowRight") {
        incrementClueIndex();
      } else if (event.code === "ArrowLeft") {
        decrementClueIndex();
      }
    });
  }, []);

  const currentClue = data.jeopardyCluesForEpisode![clueIndexRef.current];

  // @ts-ignore
  return (
    <div className="coryat-clues-container">
      <h1>{currentClue!.episodeCategory.category!.name}</h1>
      <h2>${currentClue!.value}</h2>
      {!attemptIsLive ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ maxWidth: "500px" }}>{currentClue!.text}</p>
          {
            /* @ts-ignore */
            !showAnswer ? (
              <>
                <button
                  onClick={() => {
                    setShowAnswer(true);
                  }}
                >
                  Show Answer
                </button>
                <br />
              </>
            ) : (
              <p>
                <strong
                  dangerouslySetInnerHTML={{
                    __html: currentClue!.answer!.correctResponse,
                  }}
                />
              </p>
            )
          }
          <ClueBuzzer
            buzzerActive={buzzerActive}
            buzzIn={() => {
              setPlayerBuzzed(true);
              setBuzzerActive(false);
            }}
            buzzerPermDisabled={false}
            turnOffBuzzerOnBuzz={false}
          />
          <br />
          {!playerBuzzed ? (
            <div
              style={{
                width: "1000px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ClueTimer
                numWords={0}
                activateBuzzer={() => {}}
                deactivateBuzzer={() => {
                  setBuzzerActive(false);
                }}
                playerBuzzed={false}
                timeOverride={timerOverride}
              />
            </div>
          ) : null}

          <br />
        </div>
      ) : null}
      {/* @ts-ignore */}
      {!loggedClueIDs.includes(currentClue!.id) ? (
        <>
          <button
            onClick={(e) => logResponse(e, currentClue!.id, true)}
            disabled={!attemptIsLive && !playerBuzzed}
          >
            CORRECT
          </button>
          <br />
          <br />
          <button
            onClick={(e) => logResponse(e, currentClue!.id, null)}
            disabled={!attemptIsLive && playerBuzzed}
          >
            NO ANSWER
          </button>
          <br />
          <br />
          <button
            onClick={(e) => logResponse(e, currentClue!.id, false)}
            disabled={!attemptIsLive && !playerBuzzed}
          >
            INCORRECT
          </button>
          <br />
          <br />
        </>
      ) : (
        <p>Logged.</p>
      )}
    </div>
  );
}
