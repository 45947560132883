import React, { useState } from "react";

import { graphql, useLazyLoadQuery } from "react-relay";

import type { StudySetQuery } from "./__generated__/StudySetQuery.graphql";
import Flashcard from "./Flashcard";

const GET_STUDY_SET = graphql`
  query StudySetQuery {
    jeopardyStudySet {
      id
      text
      correctResponse

      answer {
        correctResponse
        timesSeen
      }

      value
      episodeCategory {
        category {
          name
        }

        episode {
          airDate
        }

        comments
      }
    }

    whoAmI {
      username
    }
  }
`;

export default function StudySet() {
  const [numRefetches, setNumRefetches] = useState(0);

  const [currClue, setCurrClue] = useState(0);

  const [showClue, setShowClue] = useState(true);

  const data = useLazyLoadQuery<StudySetQuery>(
    GET_STUDY_SET,
    {},
    { fetchKey: numRefetches, fetchPolicy: "network-only" }
  );

  function incrementClue() {
    if (currClue + 1 === data.jeopardyStudySet?.length) {
      setCurrClue(0);
      setNumRefetches(numRefetches + 1);
      setShowClue(true);
    } else {
      setCurrClue(currClue + 1);
      setShowClue(true);
    }
  }

  return (
    <Flashcard
      clue={data!.jeopardyStudySet![currClue]}
      incrementClue={incrementClue}
      showClue={showClue}
      toggleClue={() => {
        setShowClue(!showClue);
      }}
      loggedIn={data.whoAmI !== null}
    />
  );
}
