/**
 * @generated SignedSource<<3e8efab754133b5d88f60178d5fe36c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LogFlashcardResponseInput = {
  clueId: string;
  correct: boolean;
  clientMutationId?: string | null;
};
export type FlashcardLogResponseMutation$variables = {
  input: LogFlashcardResponseInput;
};
export type FlashcardLogResponseMutationVariables = FlashcardLogResponseMutation$variables;
export type FlashcardLogResponseMutation$data = {
  readonly logFlashcardResponse: {
    readonly success: boolean | null;
  } | null;
};
export type FlashcardLogResponseMutationResponse = FlashcardLogResponseMutation$data;
export type FlashcardLogResponseMutation = {
  variables: FlashcardLogResponseMutationVariables;
  response: FlashcardLogResponseMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "LogFlashcardResponsePayload",
    "kind": "LinkedField",
    "name": "logFlashcardResponse",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FlashcardLogResponseMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FlashcardLogResponseMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6b65964b81a28a8c5cc58c481898852a",
    "id": null,
    "metadata": {},
    "name": "FlashcardLogResponseMutation",
    "operationKind": "mutation",
    "text": "mutation FlashcardLogResponseMutation(\n  $input: LogFlashcardResponseInput!\n) {\n  logFlashcardResponse(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "9242fc7c4bb73dc88717e4f9c7382c75";

export default node;
