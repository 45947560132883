import React, { useEffect, useState } from "react";
import type { JeopardyBoardQuery } from "./__generated__/JeopardyBoardQuery.graphql";
import "./JeopardyBoard.css";
import useWebSocket, { ReadyState } from "react-use-websocket";
// @ts-ignore
import ScaleText from "react-scale-text";
import useWindowSize from "../../../hooks/useWindowSize";

import { graphql, useLazyLoadQuery } from "react-relay";
import ClueDisplay from "./ClueDisplay";
import FinalJeopardy from "./FinalJeopardy";

const JEOPARDY_QUERY = graphql`
  query JeopardyBoardQuery($gameCode: String!) {
    jeopardyGameSession(gameCode: $gameCode) {
      selectedClues {
        edges {
          node {
            id
          }
        }
      }

      admin {
        username
      }

      jeopardygamesessionplayerSet {
        edges {
          node {
            name
            active
            points
          }
        }
      }

      jeopardyCategories {
        edges {
          node {
            episode {
              jarchiveGameId
            }
            name
            category {
              name
            }
            comments
            jeopardyclueSet {
              edges {
                node {
                  id
                  text
                  answer {
                    correctResponse
                  }
                  value
                  dailyDouble
                }
              }
            }
          }
        }
      }

      doubleJeopardyCategories {
        edges {
          node {
            episode {
              jarchiveGameId
            }
            name
            category {
              name
            }
            comments
            jeopardyclueSet {
              edges {
                node {
                  id
                  text
                  answer {
                    correctResponse
                  }
                  value
                  dailyDouble
                }
              }
            }
          }
        }
      }

      finalJeopardyClue {
        episodeCategory {
          category {
            name
          }
        }

        text
        answer {
          correctResponse
        }
      }
    }

    whoAmI {
      username
    }
  }
`;

export default function JeopardyBoard(props: {
  gameCode: string;
  playerName: string;
}) {
  const data = useLazyLoadQuery<JeopardyBoardQuery>(
    JEOPARDY_QUERY,
    { gameCode: props.gameCode },
    {}
  );

  const [windowWidth, windowHeight] = useWindowSize();

  const playerIsAdmin =
    data?.whoAmI?.username === data?.jeopardyGameSession?.admin.username;

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `${process.env.REACT_APP_WEBSOCKET_URL}${props.gameCode}/`,
    {
      shouldReconnect: (closeEvent) => true,
    }
  );
  const [messageHistory, setMessageHistory] = useState([]);
  const [currentPlayers, setCurrentPlayers] = useState(
    data.jeopardyGameSession?.jeopardygamesessionplayerSet?.edges
      .filter((playerNode) => {
        return playerNode?.node?.active;
      })
      .map((playerNode) => {
        return playerNode!.node!.name;
      })
  );

  const [currentRound, setCurrentRound] = useState("Jeopardy!");

  let currentRoundCategories: any[] = [];
  if (currentRound === "Jeopardy!") {
    currentRoundCategories = [
      ...data.jeopardyGameSession!.jeopardyCategories.edges,
    ];
  } else {
    currentRoundCategories = [
      ...data.jeopardyGameSession!.doubleJeopardyCategories.edges,
    ];
  }

  const [currentCluePicker, setCurrentCluePicker] = useState(
    // @ts-ignore
    data.jeopardyGameSession.jeopardygamesessionplayerSet.edges[0].node.name
  );

  const playerData =
    data.jeopardyGameSession?.jeopardygamesessionplayerSet?.edges;

  const [buzzTimes, setBuzzTimes]: [{ string: number | null } | {}, any] =
    useState({});

  const [minBuzzTime, setMinBuzzTime] = useState(0);

  useEffect(() => {
    let buzzTime: number = 99999999;

    for (const [key, value] of Object.entries(buzzTimes)) {
      if (value == null) {
        continue;
      }

      // @ts-ignore
      if (value < buzzTime) {
        // @ts-ignore
        buzzTime = value;
      }
    }

    setMinBuzzTime(buzzTime);
  }, [buzzTimes]);

  const [scores, setScores] = useState(
    Object.assign(
      {},
      ...playerData!.map((playerNode) => ({
        [playerNode!.node!.name]: playerNode!.node!.points,
      }))
    )
  );

  const [showDailyDoubleClue, setShowDailyDoubleClue] = useState(false);

  const [fjWagers, setFjWagers] = useState({});
  const [showFjClue, setShowFjClue] = useState(false);
  const [fjAnswers, setFjAnswers] = useState({});
  const [showFjCorrectResponse, setShowFjCorrectResponse] = useState(false);

  const [displayAnswer, setDisplayAnswer] = useState(false);

  const [selectedClues, setSelectedClues]: [string[], any] = useState(
    data!.jeopardyGameSession!.selectedClues.edges.map((clueNode) => {
      return clueNode!.node!.id;
    })
  );

  useEffect(() => {
    if (currentRound === "Jeopardy!" && selectedClues.length >= 30) {
      setCurrentRound("Double Jeopardy!");
    } else if (
      currentRound === "Double Jeopardy!" &&
      selectedClues.length >= 60
    ) {
      setCurrentRound("Final Jeopardy!");
    }
  }, [selectedClues, currentRound]);

  const [shownClue, setShownClue]: [string | undefined, any] =
    useState(undefined);

  function sendJSONMessage(eventType: string, message: string) {
    sendMessage(
      JSON.stringify({
        gameCode: props.gameCode,
        event: eventType,
        message: message,
      })
    );
  }

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendJSONMessage("NEW_PLAYER", props.playerName);
    }
  }, [readyState]);

  useEffect(() => {
    if (lastMessage !== null) {
      // @ts-ignore
      setMessageHistory((prev) => prev.concat(lastMessage));
    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
    if (lastMessage !== null) {
      const msgData = JSON.parse(lastMessage.data);
      if (msgData.event === "NEW_PLAYER") {
        if (!currentPlayers?.includes(msgData.message)) {
          setCurrentPlayers((prev) => prev?.concat(msgData.message));
        }
      } else if (msgData.event === "SHOW_CLUE") {
        setDisplayAnswer(false);

        currentRoundCategories.map((catNode) => {
          catNode?.node?.jeopardyclueSet.edges.map(
            (clueNode: { node: { id: any } }) => {
              if (clueNode?.node?.id === msgData.message) {
                setShownClue(clueNode);
              }
            }
          );
        });
      } else if (msgData.event === "SHOW_DAILY_DOUBLE_CLUE") {
        setShowDailyDoubleClue(true);
      } else if (msgData.event === "SHOW_ANSWER") {
        setDisplayAnswer(true);
      } else if (msgData.event === "SINGLE_SHOW_ANSWER") {
        const plyrData = JSON.parse(msgData.message);

        if (plyrData.player === props.playerName) {
          setDisplayAnswer(true);
        }
      } else if (msgData.event === "CLEAR_CLUE") {
        setShownClue(undefined);
        setBuzzTimes({});
        setShowDailyDoubleClue(false);

        // @ts-ignore
        setSelectedClues((prevState: string[]) => {
          return [...prevState, msgData.message];
        });
      } else if (msgData.event === "PLAYER_LEAVE") {
        if (currentPlayers?.includes(msgData.message)) {
          setCurrentPlayers((prevState: string[] | undefined) => {
            return prevState?.filter((player) => player !== msgData.message);
          });
        }
      } else if (msgData.event === "BUZZ_IN") {
        const buzzData = JSON.parse(msgData.message);

        setBuzzTimes((prevTimes: { [x: string]: number | null }) => {
          // @ts-ignore
          prevTimes[buzzData.player] = parseInt(buzzData.buzzTime);
          return { ...buzzTimes };
        });
      } else if (msgData.event == "NO_BUZZ") {
        const buzzData = JSON.parse(msgData.message);

        setBuzzTimes((prevTimes: { [x: string]: number | null }) => {
          prevTimes[buzzData.player] = null;
          return { ...prevTimes };
        });
      } else if (msgData.event == "NO_BUZZ") {
        const buzzData = JSON.parse(msgData.message);

        setBuzzTimes((prevTimes: { [x: string]: number | null }) => {
          prevTimes[buzzData.player] = null;
          return prevTimes;
        });
      } else if (msgData.event === "POINTS_UPDATE") {
        const pointsData = JSON.parse(msgData.message);

        // @ts-ignore
        setScores((prevScores) => {
          prevScores[pointsData.player] = parseInt(pointsData.points);

          return { ...prevScores };
        });
      } else if (msgData.event === "CLUE_PICKER_UPDATE") {
        setCurrentCluePicker(msgData.message);
      } else if (msgData.event === "FJ_WAGER") {
        const wagerData = JSON.parse(msgData.message);

        // @ts-ignore
        setFjWagers((prevWagers) => {
          // @ts-ignore
          prevWagers[wagerData.player] = parseInt(wagerData.wager);

          setFjWagers({ ...prevWagers });
        });
      } else if (msgData.event === "SHOW_FJ_CLUE") {
        setShowFjClue(true);
      } else if (msgData.event === "FJ_ANSWER") {
        const answerData = JSON.parse(msgData.message);

        setFjAnswers((prevAnswers) => {
          // @ts-ignore
          prevAnswers[answerData.player] = answerData.answer;

          return { ...prevAnswers };
        });
      } else if (msgData.event === "SHOW_FJ_CORRECT_RESPONSE") {
        setShowFjCorrectResponse(true);
      }
    }
  }, [lastMessage]);

  function showClue(clue: any) {
    if (!playerIsAdmin) {
      return;
    }

    sendJSONMessage("SHOW_CLUE", clue.node.id);
  }

  function submitBuzz(buzzTime: number | null) {
    if (buzzTime) {
      sendJSONMessage(
        "BUZZ_IN",
        JSON.stringify({
          player: props.playerName,
          buzzTime: buzzTime.toString(),
        })
      );
    } else {
      sendJSONMessage("NO_BUZZ", JSON.stringify({ player: props.playerName }));
    }
  }

  function showAnswer() {
    if (!playerIsAdmin) {
      return;
    }
    sendJSONMessage("SHOW_ANSWER", "");
  }

  function setClueSelected(
    clueId: string,
    pointAllocations: { [x: string]: number }
  ) {
    if (!playerIsAdmin) {
      return;
    }
    sendJSONMessage("CLEAR_CLUE", clueId);

    allocatePoints(pointAllocations);
  }

  function allocatePoints(pointAllocations: { [s: string]: number }) {
    let correctResponseGiven = false;

    for (const [player, points] of Object.entries(pointAllocations)) {
      if (points > 0) {
        correctResponseGiven = true;

        sendJSONMessage("CLUE_PICKER_UPDATE", player);
      }

      sendJSONMessage(
        "ALLOCATE_POINTS",
        JSON.stringify({ player: player, points: points })
      );
    }
  }

  useEffect(() => {
    let headers = document.getElementsByClassName("jeopardy-category-header");

    // @ts-ignore
    for (const header of headers) {
      while (
        header.scrollHeight > header.clientHeight ||
        header.scrollWidth > header.clientWidth
      ) {
        let style = window
          .getComputedStyle(header, null)
          .getPropertyValue("font-size");
        let fontSize = parseFloat(style);

        if (fontSize <= 1) {
          break;
        }

        header.style.fontSize = "" + (fontSize - 1) + "px";
      }
    }
  }, [windowWidth, windowHeight, currentRound]);

  return (
    <>
      <div
        className={`jeopardy-status-connection jeopardy-status-${
          connectionStatus === "Open"
            ? "connected"
            : connectionStatus === "Closed"
            ? "disconnected"
            : "connecting"
        }`}
      >
        <strong>
          {connectionStatus === "Open"
            ? "Connected"
            : connectionStatus === "Closed"
            ? "Disconnected"
            : "Connecting..."}
        </strong>
      </div>
      <div className="jeopardy-board-players">
        {currentPlayers ? (
          <>
            {currentPlayers.map((player) => {
              // @ts-ignore
              return (
                <div className="jeopardy-board-player">
                  <div
                    className="jeopardy-board-player-selecting"
                    style={{ opacity: player === currentCluePicker ? 1 : 0 }}
                  />
                  {player}
                  {playerIsAdmin ? (
                    <>
                      <button
                        onClick={() => {
                          sendJSONMessage(
                            "SINGLE_SHOW_ANSWER",
                            JSON.stringify({ player: player })
                          );
                        }}
                      >
                        Show Ans
                      </button>
                    </>
                  ) : null}
                  {player in buzzTimes ? (
                    // @ts-ignore
                    buzzTimes[player] ? (
                      // @ts-ignore
                      <span
                        style={
                          // @ts-ignore
                          buzzTimes[player] === minBuzzTime
                            ? { fontWeight: "bold", color: "green" }
                            : undefined
                        }
                      >
                        Buzzed in{" "}
                        {
                          // @ts-ignore
                          buzzTimes[player]
                        }{" "}
                        ms
                      </span>
                    ) : (
                      <span>NO BUZZ</span>
                    )
                  ) : null}
                  {fjWagers && player in fjWagers ? (
                    <span>Wagered.</span>
                  ) : null}
                  {fjAnswers && player in fjAnswers ? (
                    <span>Answered.</span>
                  ) : null}
                  <span>
                    <strong>${player in scores ? scores[player] : 0}</strong>
                  </span>
                </div>
              );
            })}
          </>
        ) : null}
      </div>
      {currentRound !== "Final Jeopardy!" ? (
        <div className="jeopardy-board-container noselect">
          {shownClue ? (
            <ClueDisplay
              clue={shownClue}
              showAnswer={showAnswer}
              displayAnswer={displayAnswer}
              exitClue={setClueSelected}
              isAdmin={playerIsAdmin}
              submitBuzz={submitBuzz}
              playerNames={currentPlayers}
              currentlySelectingPlayer={currentCluePicker}
              maxDailyDoubleWager={
                currentCluePicker in scores ? scores[currentCluePicker] : 0
              }
              currentRound={currentRound}
              showDailyDoubleClue={showDailyDoubleClue}
              sendJSONMessage={sendJSONMessage}
            />
          ) : null}
          <table className="jeopardy-board">
            {currentRoundCategories.map((categoryNode) => {
              const category = categoryNode?.node;

              // @ts-ignore
              const clueSet = [...category.jeopardyclueSet.edges];

              if (clueSet) {
                // @ts-ignore
                clueSet.sort((a, b) => a.node.value - b.node.value);
              }

              return (
                <tr>
                  <th>
                    <div
                      className="jeopardy-category-header"
                      style={{
                        overflow: "scroll",
                        fontSize: "30px",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      {category?.category?.name}
                    </div>
                  </th>
                  {clueSet.map((clue) => {
                    let cellValue: string = `$${clue?.node?.value}`;

                    const cellSelected =
                      typeof clue?.node?.id === "string" &&
                      selectedClues.includes(clue.node.id);

                    if (!clue?.node?.text) {
                      cellValue = "";
                    } else if (cellSelected) {
                      cellValue = "";
                    }

                    return (
                      <td
                        onClick={(e) => {
                          e.preventDefault();

                          if (cellSelected || shownClue) {
                            return;
                          }

                          if (clue?.node?.text) {
                            showClue(clue);
                          }
                        }}
                        className={
                          !cellSelected
                            ? "jeopardy-dollar-value"
                            : "jeopardy-board-selected"
                        }
                        key={clue!.node!.id}
                      >
                        {cellValue}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </table>
        </div>
      ) : (
        <FinalJeopardy
          clue={data.jeopardyGameSession?.finalJeopardyClue}
          currentPlayerScore={
            props.playerName in scores ? scores[props.playerName] : 0
          }
          sendJSONMessage={sendJSONMessage}
          playerName={props.playerName}
          showfJClue={showFjClue}
          showFJCorrectResponse={showFjCorrectResponse}
          isAdmin={playerIsAdmin}
          fjWagers={fjWagers}
          fjAnswers={fjAnswers}
          allocatePoints={allocatePoints}
        />
      )}
    </>
  );
}
