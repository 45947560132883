/**
 * @generated SignedSource<<4b14847a8b4bba4b02e8ab5769e56bd2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ClueDisplayQuery$variables = {
  playerName: string;
  gameCode: string;
};
export type ClueDisplayQueryVariables = ClueDisplayQuery$variables;
export type ClueDisplayQuery$data = {
  readonly triviaGameClueTextForPlayer: {
    readonly displayClue: string | null;
    readonly realClue: string | null;
    readonly clueSwapped: boolean | null;
    readonly blackedOut: boolean | null;
  } | null;
};
export type ClueDisplayQueryResponse = ClueDisplayQuery$data;
export type ClueDisplayQuery = {
  variables: ClueDisplayQueryVariables;
  response: ClueDisplayQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gameCode"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "playerName"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "gameCode",
        "variableName": "gameCode"
      },
      {
        "kind": "Variable",
        "name": "playerName",
        "variableName": "playerName"
      }
    ],
    "concreteType": "TriviaGameClueTextType",
    "kind": "LinkedField",
    "name": "triviaGameClueTextForPlayer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayClue",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "realClue",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clueSwapped",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blackedOut",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClueDisplayQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClueDisplayQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c9f2105e7aa2badaf0749d2c02cc2f7e",
    "id": null,
    "metadata": {},
    "name": "ClueDisplayQuery",
    "operationKind": "query",
    "text": "query ClueDisplayQuery(\n  $playerName: String!\n  $gameCode: String!\n) {\n  triviaGameClueTextForPlayer(playerName: $playerName, gameCode: $gameCode) {\n    displayClue\n    realClue\n    clueSwapped\n    blackedOut\n  }\n}\n"
  }
};
})();

(node as any).hash = "a544a883687f8067177ce77352298032";

export default node;
