/**
 * @generated SignedSource<<d6c383c189945796c50a2a532cc67cdd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateTriviaGameSessionInput = {
  gameCode: string;
  playerName: string;
  clientMutationId?: string | null;
};
export type TriviaGamePageCreateGameMutation$variables = {
  input: CreateTriviaGameSessionInput;
};
export type TriviaGamePageCreateGameMutationVariables = TriviaGamePageCreateGameMutation$variables;
export type TriviaGamePageCreateGameMutation$data = {
  readonly createTriviaGameSession: {
    readonly success: boolean | null;
  } | null;
};
export type TriviaGamePageCreateGameMutationResponse = TriviaGamePageCreateGameMutation$data;
export type TriviaGamePageCreateGameMutation = {
  variables: TriviaGamePageCreateGameMutationVariables;
  response: TriviaGamePageCreateGameMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateTriviaGameSessionPayload",
    "kind": "LinkedField",
    "name": "createTriviaGameSession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TriviaGamePageCreateGameMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TriviaGamePageCreateGameMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bf12d1ed814a458dd5e2d1fce685e503",
    "id": null,
    "metadata": {},
    "name": "TriviaGamePageCreateGameMutation",
    "operationKind": "mutation",
    "text": "mutation TriviaGamePageCreateGameMutation(\n  $input: CreateTriviaGameSessionInput!\n) {\n  createTriviaGameSession(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb2bc5c6922a12b821313aebea5f730e";

export default node;
