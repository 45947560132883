import React from "react";
import "./TargetSelector.css";

export default function TargetSelector({ players, itemNum, submitTarget }) {
  return (
    <div>
      <h6>Select a Target</h6>
      <br />
      {players.map((player) => {
        return (
          <div>
            <button onClick={(e) => submitTarget(itemNum, player)}>
              {player}
            </button>
            <br />
          </div>
        );
      })}
    </div>
  );
}
