import React from "react";
import "./TriviaLeaderboard.css";

export default function TriviaLeaderboard(props: {
  currentPlayers: string[];
  scores: any;
  playersWithAnswers: string[];
}) {
  const sortedPlayers = props.currentPlayers.sort((a, b) => {
    let aVal = 0;
    let bVal = 0;

    if (a in props.scores) {
      aVal = props.scores[a];
    }

    if (b in props.scores) {
      bVal = props.scores[b];
    }

    return bVal - aVal;
  });

  return (
    <div>
      {sortedPlayers.map((player) => {
        return (
          <div className="jeopardy-board-player">
            {player}
            <span>
              <strong>
                {player in props.scores ? props.scores[player] : 0}
              </strong>
            </span>
            {props.playersWithAnswers.includes(player) ? (
              <>
                <span>Answered.</span>
              </>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}
