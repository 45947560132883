import React, { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import "./ClueTimer.css";

export default function ClueTimer(props: {
  numWords: number;
  activateBuzzer: () => void;
  deactivateBuzzer: (sendNoBuzzMessage: boolean) => void;
  playerBuzzed: boolean;
  timeOverride?: number;
}) {
  const CLUE_READ_TIMER_INTERVAL = 100;
  const CLUE_TIME_TO_BUZZ = 5 * 1000;

  useEffect(() => {
    setProgress(0);
    setClueReadTimerActive(true);
    setClueReadTimer(0);
  }, [props.timeOverride]);

  const [progress, setProgress] = useState(0);

  const [clueReadTimer, setClueReadTimer] = useState(0);
  const [clueBuzzTimer, setClueBuzzTimer] = useState(0);

  const [clueReadTimerActive, setClueReadTimerActive] = useState(true);
  const [clueBuzzTimerActive, setClueBuzzTimerActive] = useState(false);

  const timeToRead = !props.timeOverride
    ? Math.max(3000, Math.round(props.numWords / (200 / 60)) * 1000)
    : props.timeOverride;

  useEffect(() => {
    // @ts-ignore
    let buzzInterval = null;

    if (clueBuzzTimerActive && !props.playerBuzzed) {
      buzzInterval = setInterval(() => {
        setClueBuzzTimer((timer) => timer + CLUE_READ_TIMER_INTERVAL);
      }, CLUE_READ_TIMER_INTERVAL);
    } else {
      if (buzzInterval) clearInterval(buzzInterval);
    }

    // @ts-ignore
    return () => clearInterval(buzzInterval);
  }, [clueBuzzTimerActive, clueBuzzTimer, props.playerBuzzed]);

  useEffect(() => {
    setProgress((clueBuzzTimer / CLUE_TIME_TO_BUZZ) * 100);

    if (clueBuzzTimer >= CLUE_TIME_TO_BUZZ) {
      setClueBuzzTimerActive(false);

      props.deactivateBuzzer(true);
    }
  }, [clueBuzzTimer]);

  useEffect(() => {
    // @ts-ignore
    let interval = null;
    if (clueReadTimerActive) {
      interval = setInterval(() => {
        setClueReadTimer((timer) => timer + CLUE_READ_TIMER_INTERVAL);
      }, CLUE_READ_TIMER_INTERVAL);
    } else if (!clueReadTimerActive && clueReadTimer !== 0) {
      // @ts-ignore
      clearInterval(interval);
    }
    // @ts-ignore
    return () => clearInterval(interval);
  }, [clueReadTimerActive, clueReadTimer]);

  useEffect(() => {
    setProgress((clueReadTimer / timeToRead) * 100);

    if (clueReadTimer >= timeToRead) {
      setClueReadTimerActive(false);

      if (!props.timeOverride) {
        setClueBuzzTimerActive(true);
        setProgress(0);
      } else {
        props.deactivateBuzzer(false);
      }

      props.activateBuzzer();
    }
  }, [clueReadTimer]);

  let timerProgressColor = props.timeOverride ? "green" : "red";
  let timerFinishedColor = props.timeOverride ? "red" : "green";

  if (!clueReadTimerActive && !props.timeOverride) {
    timerProgressColor = "black";
    timerFinishedColor = "black";
  }

  return (
    <div
      className="clue-timer-container"
      style={
        !clueReadTimerActive && !props.timeOverride
          ? { backgroundColor: "green" }
          : undefined
      }
    >
      <div
        className="clue-timer-progress"
        style={{
          backgroundColor:
            progress >= 100 ? timerFinishedColor : timerProgressColor,
          width: `${progress}%`,
        }}
      />
    </div>
  );
}
