import React, { useState } from "react";
import "./PointAllocator.css";

export default function PointAllocator(props: {
  players: string[] | undefined;
  clueValue: number;
  dailyDoublePlayer: string | undefined;
  allocatePoints: any;
}) {
  const [pointAllocations, setPointAllocations]: [
    { [x: string]: number },
    any
  ] = useState({});

  if (!props.players) {
    return <></>;
  }

  function allocatePoints(player: string, points: number) {
    if (
      points > 0 &&
      Object.values(pointAllocations).some((points) => points > 0)
    ) {
      alert("You can only have one correct player per question.");

      return;
    }

    setPointAllocations((oldAlloc: { [x: string]: number }) => {
      oldAlloc[player] = points;
      return { ...oldAlloc };
    });
  }

  let players = props.players!;

  if (props.dailyDoublePlayer) {
    players = players.filter((p) => p === props.dailyDoublePlayer);
  }

  return (
    <>
      <div className="jeopardy-point-allocator">
        {players.map((player) => (
          <div key={player}>
            {player}
            <br />
            <button
              className="jeopardy-point-allocator-button jeopardy-point-allocator-correct"
              onClick={() => allocatePoints(player, props.clueValue)}
            />
            <button
              className="jeopardy-point-allocator-button"
              onClick={(e) => {
                if (player in pointAllocations) {
                  setPointAllocations((prevAlloc: { [x: string]: number }) => {
                    delete prevAlloc[player];
                    return { ...prevAlloc };
                  });
                }
              }}
            />
            <button
              className="jeopardy-point-allocator-button jeopardy-point-allocator-incorrect"
              onClick={() => allocatePoints(player, props.clueValue * -1)}
            />

            {player in pointAllocations ? (
              pointAllocations[player] > 0 ? (
                <p style={{ color: "lightgreen" }}>
                  <strong>Correct</strong>
                </p>
              ) : (
                <p style={{ color: "indianred" }}>
                  <strong>Incorrect</strong>
                </p>
              )
            ) : (
              <p />
            )}
          </div>
        ))}
      </div>
      <button
        onClick={() => {
          props.allocatePoints(pointAllocations);
        }}
      >
        Allocate Points
      </button>
    </>
  );
}
