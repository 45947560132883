/**
 * @generated SignedSource<<6111c941c64ef51dcd64e22c099bc662>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TriviaGameSessionValidateParamsInput = {
  gameCode: string;
  playerName: string;
  clientMutationId?: string | null;
};
export type TriviaGamePageValidateParamsMutation$variables = {
  input: TriviaGameSessionValidateParamsInput;
};
export type TriviaGamePageValidateParamsMutationVariables = TriviaGamePageValidateParamsMutation$variables;
export type TriviaGamePageValidateParamsMutation$data = {
  readonly triviaGameSessionCheckValidParams: {
    readonly success: boolean | null;
  } | null;
};
export type TriviaGamePageValidateParamsMutationResponse = TriviaGamePageValidateParamsMutation$data;
export type TriviaGamePageValidateParamsMutation = {
  variables: TriviaGamePageValidateParamsMutationVariables;
  response: TriviaGamePageValidateParamsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TriviaGameSessionValidateParamsPayload",
    "kind": "LinkedField",
    "name": "triviaGameSessionCheckValidParams",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TriviaGamePageValidateParamsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TriviaGamePageValidateParamsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "368604e7c48d7311f9a73f824f763b7c",
    "id": null,
    "metadata": {},
    "name": "TriviaGamePageValidateParamsMutation",
    "operationKind": "mutation",
    "text": "mutation TriviaGamePageValidateParamsMutation(\n  $input: TriviaGameSessionValidateParamsInput!\n) {\n  triviaGameSessionCheckValidParams(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "cd113dd87d02db104e1543612d302f1f";

export default node;
