/**
 * @generated SignedSource<<0a36d82ecb22fb704230d7531d68907c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type JeopardyCategoriesSelectQuery$variables = {};
export type JeopardyCategoriesSelectQueryVariables = JeopardyCategoriesSelectQuery$variables;
export type JeopardyCategoriesSelectQuery$data = {
  readonly jeopardyMetaCategories: ReadonlyArray<{
    readonly id: string;
    readonly parent: {
      readonly id: string;
    } | null;
    readonly name: string;
  } | null> | null;
};
export type JeopardyCategoriesSelectQueryResponse = JeopardyCategoriesSelectQuery$data;
export type JeopardyCategoriesSelectQuery = {
  variables: JeopardyCategoriesSelectQueryVariables;
  response: JeopardyCategoriesSelectQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "JeopardyMetaCategoryType",
    "kind": "LinkedField",
    "name": "jeopardyMetaCategories",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "JeopardyMetaCategoryType",
        "kind": "LinkedField",
        "name": "parent",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "JeopardyCategoriesSelectQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "JeopardyCategoriesSelectQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "45aa90396b9e6d033838e70e2ed12983",
    "id": null,
    "metadata": {},
    "name": "JeopardyCategoriesSelectQuery",
    "operationKind": "query",
    "text": "query JeopardyCategoriesSelectQuery {\n  jeopardyMetaCategories {\n    id\n    parent {\n      id\n    }\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "629bdf1fe534ed2cda269c9dc813c20e";

export default node;
