import React, { useState } from "react";
import JeopardyCategoriesSelect from "../../components/JeopardyCategoriesSelect";
import "./JeopardyCategorizationPage.css";
import JeopardyCategorizationCluesList from "./JeopardyCategorizationCluesList";

import { graphql, useMutation } from "react-relay";

import { JeopardyCategorizationPageSubmitMutation } from "./__generated__/JeopardyCategorizationPageSubmitMutation.graphql";

const SUBMIT_CATEGORIZATION = graphql`
  mutation JeopardyCategorizationPageSubmitMutation(
    $input: MetaCategorizeJeopardyCluesInput!
  ) {
    metaCategorizeJeopardyClues(input: $input) {
      success
      cluesCategorized
    }
  }
`;

export default function JeopardyCategorizationPage() {
  const [selectedCategoryIDs, setSelectedCategoryIDs]: [string[], any] =
    useState([]);

  const [ignoredClueIDs, setIgnoredClueIDs]: [string[], any] = useState([]);
  const [lastCategorizationAmount, setLastCategorizationAmount]: [
    number | undefined,
    any
  ] = useState(undefined);

  const [categorizationMutation] =
    useMutation<JeopardyCategorizationPageSubmitMutation>(
      SUBMIT_CATEGORIZATION
    );

  const [clueFetchKey, setClueFetchKey] = useState(0);

  function updateSelectedClueIDs(clueID: string) {
    const idIndex = ignoredClueIDs.indexOf(clueID);

    if (idIndex > -1) {
      setIgnoredClueIDs((prevState: string[]) => {
        prevState.splice(idIndex, 1);
        return [...prevState];
      });
    } else {
      setIgnoredClueIDs((prevState: string[]) => {
        return [...prevState, clueID];
      });
    }
  }

  function updateCategoryIDSelection(categoryID: string) {
    const idIndex = selectedCategoryIDs.indexOf(categoryID);

    if (idIndex > -1) {
      setSelectedCategoryIDs((prevState: string[]) => {
        prevState.splice(idIndex, 1);
        return [...prevState];
      });
    } else {
      setSelectedCategoryIDs((prevState: string[]) => {
        return [...prevState, categoryID];
      });
    }
  }

  function submitCategorizations(
    clueIds: string[],
    propagateClueIds: string[]
  ) {
    categorizationMutation({
      variables: {
        input: { clueIds, propagateClueIds, categoryIds: selectedCategoryIDs },
      },
      onCompleted: (res) => {
        if (res?.metaCategorizeJeopardyClues?.success) {
          setLastCategorizationAmount(
            res!.metaCategorizeJeopardyClues.cluesCategorized
          );
          setClueFetchKey((prev) => prev + 1);
          setSelectedCategoryIDs([]);
        } else {
          alert("Failed to submit mutation.");
          setLastCategorizationAmount(undefined);
        }
      },
    });
  }

  return (
    <div className="jeopardy-categorization-container">
      <div className="jeopardy-categorization-clues">
        <React.Suspense fallback={<div>Loading clues...</div>}>
          <JeopardyCategorizationCluesList
            selectedClueIDs={ignoredClueIDs}
            updateClueIDSelections={updateSelectedClueIDs}
            submitCategorizations={submitCategorizations}
            clueFetchKey={clueFetchKey}
            setClueFetchKey={setClueFetchKey}
            lastCategorizationAmount={lastCategorizationAmount}
          />
        </React.Suspense>
      </div>
      <div className="jeopardy-categorization-category-select">
        <React.Suspense fallback={<>Loading Categories...</>}>
          <JeopardyCategoriesSelect
            selectedCategoryIDs={selectedCategoryIDs}
            updateCategoryIDSelection={updateCategoryIDSelection}
          />
        </React.Suspense>
      </div>
    </div>
  );
}
