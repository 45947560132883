import React from "react";

import { graphql, useLazyLoadQuery, useMutation } from "react-relay";

import { CoryatHistoryQuery } from "./__generated__/CoryatHistoryQuery.graphql";
import { CoryatEpisodeSelectCreateAttemptMutation } from "../CoryatEpisodeSelect/__generated__/CoryatEpisodeSelectCreateAttemptMutation.graphql";
import { CREATE_ATTEMPT } from "../CoryatEpisodeSelect";

const CORYAT_ATTEMPTS = graphql`
  query CoryatHistoryQuery {
    jeopardyCoryatAttempts(completed: true) {
      id

      jeopardyEpisode {
        id
        episodeNumber
        airDate
        participants
        season
      }

      live
      coryatScore
    }
  }
`;

export default function CoryatHistory(props: {
  setSelectedEpisodeID: (episodeID: string) => void;
  setAttemptID: (attemptID: string) => void;
}) {
  const [createCoryatAttempt] =
    useMutation<CoryatEpisodeSelectCreateAttemptMutation>(CREATE_ATTEMPT);

  const data = useLazyLoadQuery<CoryatHistoryQuery>(CORYAT_ATTEMPTS, {}, {});

  return (
    <div>
      <h1>Complete Attempts</h1>
      <ul>
        {data.jeopardyCoryatAttempts!.map((attempt) => {
          const episode = attempt!.jeopardyEpisode;

          return (
            <li>
              {episode!.episodeNumber} ({episode!.season}): {episode!.airDate} -
              ${attempt!.coryatScore}{" "}
              <button
                onClick={() => {
                  const live = window.confirm("Is this attempt live?");

                  createCoryatAttempt({
                    variables: {
                      input: { episodeId: episode!.id, live: live },
                    },
                    onCompleted: (data) => {
                      props.setAttemptID(
                        data.createJeopardyCoryatAttempt!.attemptId!
                      );
                      props.setSelectedEpisodeID(episode!.id);
                    },
                  });
                }}
              >
                Replay
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
