import React, { useState } from "react";
import CoryatEpisodeSelect from "./CoryatEpisodeSelect";
import CoryatClues from "./CoryatClues";
import CoryatHistory from "./CoryatHistory";

export default function JeopardyCoryatPage() {
  const [selectedEpisodeID, setSelectedEpisodeID]: [string | undefined, any] =
    useState(undefined);

  const [attemptID, setAttemptID]: [string | undefined, any] =
    useState(undefined);

  return (
    <div>
      {!selectedEpisodeID || !attemptID ? (
        <>
          <React.Suspense fallback={<p>Loading episodes...</p>}>
            <CoryatEpisodeSelect
              setSelectedEpisodeID={setSelectedEpisodeID}
              setAttemptID={setAttemptID}
            />
          </React.Suspense>
          <React.Suspense fallback={<></>}>
            <CoryatHistory
              setSelectedEpisodeID={setSelectedEpisodeID}
              setAttemptID={setAttemptID}
            />
          </React.Suspense>
        </>
      ) : (
        <React.Suspense fallback={<p>Loading clues...</p>}>
          <CoryatClues episodeID={selectedEpisodeID} attemptID={attemptID} />
        </React.Suspense>
      )}
    </div>
  );
}
