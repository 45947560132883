import React from "react";
import "./StudyClue.css";

interface IProps {
  categoryName: string;
  categoryClue: any;
  showClue: boolean;
  toggleClue: any;
}

function clueAnswerDisplay(answer: any) {
  return `<p><strong>${answer?.correctResponse}</strong><br/><br/><br/>Seen ${answer?.timesSeen} times.</p>`;
}

export default function StudyClue({
  categoryName,
  categoryClue,
  showClue,
  toggleClue,
}: IProps) {
  return (
    <table className="jeopardy-study-clue noselect">
      <tr>
        <th>
          {categoryName}
          {categoryClue.episodeCategory.comments !== "" ? (
            <>
              <br />
              <span className="jeopardy-study-clue-small-text">
                {categoryClue.episodeCategory.comments}
              </span>
            </>
          ) : null}
          <br />
          <span className="jeopardy-study-clue-small-text">
            {categoryClue.episodeCategory.episode.airDate}
          </span>
        </th>
      </tr>
      <tr>
        <td
          onClick={toggleClue}
          dangerouslySetInnerHTML={{
            __html: showClue
              ? categoryClue.text
              : clueAnswerDisplay(categoryClue.answer),
          }}
        />
      </tr>
    </table>
  );
}
