/**
 * @generated SignedSource<<0ceeb59082f8561e561ad64afd88cae2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SearchResultsPreviewQuery$variables = {
  text: string;
};
export type SearchResultsPreviewQueryVariables = SearchResultsPreviewQuery$variables;
export type SearchResultsPreviewQuery$data = {
  readonly jeopardySearchPreview: {
    readonly numResults: number | null;
  } | null;
};
export type SearchResultsPreviewQueryResponse = SearchResultsPreviewQuery$data;
export type SearchResultsPreviewQuery = {
  variables: SearchResultsPreviewQueryVariables;
  response: SearchResultsPreviewQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "text"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "text",
        "variableName": "text"
      }
    ],
    "concreteType": "JeopardySearchPreviewType",
    "kind": "LinkedField",
    "name": "jeopardySearchPreview",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "numResults",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchResultsPreviewQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SearchResultsPreviewQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "860bbb13d6261506b1907eb9fb194798",
    "id": null,
    "metadata": {},
    "name": "SearchResultsPreviewQuery",
    "operationKind": "query",
    "text": "query SearchResultsPreviewQuery(\n  $text: String!\n) {\n  jeopardySearchPreview(text: $text) {\n    numResults\n  }\n}\n"
  }
};
})();

(node as any).hash = "9c20ce56103183d6c1ab1a05b33c53bf";

export default node;
