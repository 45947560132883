/**
 * @generated SignedSource<<6bcd689441fdc94ae0dcff6ccfa0aa42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MarkCoryatAttemptCompletedInput = {
  attemptId?: string | null;
  clientMutationId?: string | null;
};
export type CoryatEpisodeSelectCompleteAttemptMutation$variables = {
  input: MarkCoryatAttemptCompletedInput;
};
export type CoryatEpisodeSelectCompleteAttemptMutationVariables = CoryatEpisodeSelectCompleteAttemptMutation$variables;
export type CoryatEpisodeSelectCompleteAttemptMutation$data = {
  readonly markCoryatAttemptCompleted: {
    readonly success: boolean | null;
  } | null;
};
export type CoryatEpisodeSelectCompleteAttemptMutationResponse = CoryatEpisodeSelectCompleteAttemptMutation$data;
export type CoryatEpisodeSelectCompleteAttemptMutation = {
  variables: CoryatEpisodeSelectCompleteAttemptMutationVariables;
  response: CoryatEpisodeSelectCompleteAttemptMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MarkCoryatAttemptCompletedPayload",
    "kind": "LinkedField",
    "name": "markCoryatAttemptCompleted",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CoryatEpisodeSelectCompleteAttemptMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CoryatEpisodeSelectCompleteAttemptMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c8567a03d8af4388af4902c3c635915f",
    "id": null,
    "metadata": {},
    "name": "CoryatEpisodeSelectCompleteAttemptMutation",
    "operationKind": "mutation",
    "text": "mutation CoryatEpisodeSelectCompleteAttemptMutation(\n  $input: MarkCoryatAttemptCompletedInput!\n) {\n  markCoryatAttemptCompleted(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "1fcd5b43b6c9dfb1b0a9ffa58b6a276b";

export default node;
