import React from "react";

import { graphql, useLazyLoadQuery } from "react-relay";

import { SearchResultsPreviewQuery } from "./__generated__/SearchResultsPreviewQuery.graphql";

const SEARCH_RESULTS_PREVIEW = graphql`
  query SearchResultsPreviewQuery($text: String!) {
    jeopardySearchPreview(text: $text) {
      numResults
    }
  }
`;

export default function SearchResultsPreview({ query }: { query: string }) {
  const data = useLazyLoadQuery<SearchResultsPreviewQuery>(
    SEARCH_RESULTS_PREVIEW,
    { text: query },
    {}
  );

  return <p>{data.jeopardySearchPreview?.numResults} results.</p>;
}
