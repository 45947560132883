import React, { useEffect, useState } from "react";
import DDImg from "../../../../assets/images/JEP38_JBuzz_1110_OOS_DailyDoublesFullClip Featured Image_1600x900.jpg";
import "./FinalJeopardy.css";
import ClueTimer from "../ClueDisplay/ClueTimer";

export default function FinalJeopardy(props: {
  clue:
    | {
        text: string | null;
        answer: { correctResponse: string } | null;
        episodeCategory: { category: { name: string } | null };
      }
    | undefined;
  currentPlayerScore: number;
  sendJSONMessage: (eventType: string, message: string) => void;
  playerName: string;
  showfJClue: boolean;
  isAdmin: boolean;
  showFJCorrectResponse: boolean;
  fjWagers: { [x: string]: number };
  fjAnswers: { [x: string]: string };
  allocatePoints: any;
}) {
  const [fjWager, setFJWager] = useState(0);
  const [fjAnswer, setFJAnswer] = useState("");
  const [wageringEnabled, setWageringEnabled] = useState(true);
  const [answeringEnabled, setAnsweringEnabled] = useState(true);

  const [allowShowAnswer, setAllowShowAnswer] = useState(false);

  const [pointsAllocated, setPointsAllocated] = useState(false);

  const [pointAllocations, setPointAllocations]: [
    { [x: string]: number },
    any
  ] = useState({});

  function allocatePoints(player: string, points: number) {
    setPointAllocations((oldAlloc: { [x: string]: number }) => {
      oldAlloc[player] = points;
      return { ...oldAlloc };
    });
  }

  const jeopardyThinkingMusicURL =
    "https://www.myinstants.com/media/sounds/jeopardy-final-jeopardy-thinking-music.mp3";

  const categoryTitle = props.clue?.episodeCategory?.category?.name;

  const maxWager = props.currentPlayerScore;

  useEffect(() => {
    if (!props.showfJClue || props.showFJCorrectResponse) {
      return;
    }

    const fjAudio = new Audio(jeopardyThinkingMusicURL);

    fjAudio.volume = 0.2;

    fjAudio.play().then();
  }, [props.showfJClue]);

  return (
    <div className="final-jeopardy-container">
      <div className="final-jeopardy-category-container">
        <h1>Final Jeopardy!</h1>
        <div className="final-jeopardy-category">
          {!props.showFJCorrectResponse ? (
            !props.showfJClue ? (
              <>
                <h1>{categoryTitle}</h1>
                {props.isAdmin ? (
                  <button
                    onClick={() => props.sendJSONMessage("SHOW_FJ_CLUE", "")}
                  >
                    Show Clue
                  </button>
                ) : null}
              </>
            ) : (
              <>
                <p>{props.clue?.text}</p>
                <ClueTimer
                  numWords={0}
                  activateBuzzer={() => {
                    setAnsweringEnabled(false);
                    setAllowShowAnswer(true);
                  }}
                  deactivateBuzzer={() => {}}
                  playerBuzzed={true}
                  timeOverride={28 * 1000}
                />
                {props.isAdmin && allowShowAnswer ? (
                  <>
                    <br />
                    <button
                      onClick={() =>
                        props.sendJSONMessage("SHOW_FJ_CORRECT_RESPONSE", "")
                      }
                    >
                      Show Answer
                    </button>
                  </>
                ) : null}
              </>
            )
          ) : (
            <>
              <p>{props.clue?.answer?.correctResponse}</p>
              <div
                className="jeopardy-point-allocator"
                style={{ fontWeight: "initial" }}
              >
                {Object.entries(props.fjWagers).map(([player, wager]) => (
                  <div key={player}>
                    {player}
                    <br />
                    Answered:{" "}
                    <strong>
                      {props.fjAnswers &&
                      player in props.fjAnswers &&
                      !(props.fjAnswers[player] === "")
                        ? props.fjAnswers[player]
                        : "[No Answer]"}
                    </strong>
                    <br />
                    Wagered: ${wager}
                    {props.isAdmin ? (
                      <>
                        <br />
                        <button
                          className="jeopardy-point-allocator-button jeopardy-point-allocator-correct"
                          onClick={() => allocatePoints(player, wager)}
                        />
                        <button
                          className="jeopardy-point-allocator-button"
                          onClick={(e) => {
                            if (player in pointAllocations) {
                              setPointAllocations(
                                (prevAlloc: { [x: string]: number }) => {
                                  delete prevAlloc[player];
                                  return { ...prevAlloc };
                                }
                              );
                            }
                          }}
                        />
                        <button
                          className="jeopardy-point-allocator-button jeopardy-point-allocator-incorrect"
                          onClick={() => allocatePoints(player, wager * -1)}
                        />
                        {player in pointAllocations ? (
                          pointAllocations[player] > 0 ? (
                            <p style={{ color: "lightgreen" }}>
                              <strong>Correct</strong>
                            </p>
                          ) : (
                            <p style={{ color: "indianred" }}>
                              <strong>Incorrect</strong>
                            </p>
                          )
                        ) : (
                          <p />
                        )}
                      </>
                    ) : null}
                  </div>
                ))}
              </div>
              {props.isAdmin ? (
                <button
                  onClick={() => {
                    props.allocatePoints(pointAllocations);

                    setPointsAllocated(true);
                  }}
                  disabled={pointsAllocated}
                >
                  Allocate Points
                </button>
              ) : null}
            </>
          )}
        </div>
        {maxWager >= 0 ? (
          <div className="final-jeopardy-wager">
            {!props.showfJClue ? (
              <label>
                <input
                  type="number"
                  onChange={(e) => {
                    setFJWager(parseInt(e.target.value));
                  }}
                  value={fjWager}
                  min={0}
                  max={maxWager}
                />{" "}
                <button
                  onClick={(e) => {
                    props.sendJSONMessage(
                      "FJ_WAGER",
                      JSON.stringify({
                        player: props.playerName,
                        wager: fjWager,
                      })
                    );

                    setWageringEnabled(false);
                  }}
                  disabled={
                    !wageringEnabled ||
                    fjWager < 0 ||
                    fjWager > maxWager ||
                    isNaN(fjWager)
                  }
                >
                  Submit Wager
                </button>{" "}
                (Must be between 0 and {maxWager})
              </label>
            ) : (
              <label className="final-jeopardy-answer">
                What is{" "}
                <input
                  type="text"
                  className="final-jeopardy-answer-input"
                  onChange={(e) => {
                    // @ts-ignore
                    setFJAnswer(e.target.value);
                  }}
                  value={fjAnswer}
                />
                ?{" "}
                <button
                  onClick={(e) => {
                    props.sendJSONMessage(
                      "FJ_ANSWER",
                      JSON.stringify({
                        player: props.playerName,
                        answer: fjAnswer,
                      })
                    );
                  }}
                  disabled={!answeringEnabled}
                >
                  Submit Answer
                </button>
              </label>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}
