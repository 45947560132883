import React, { useState } from "react";
import "./KeywordSearch.css";
import SearchResults from "./SearchResults";
import SearchResultsPreview from "./SearchResultsPreview";

export default function KeywordSearch() {
  const [searchQuery, setSearchQuery]: [string | undefined, any] =
    useState(undefined);

  const [activeSearchQuery, setActiveSearchQuery]: [string | undefined, any] =
    useState(undefined);

  function submitSearch(e: any) {
    e.preventDefault();

    setActiveSearchQuery(searchQuery);
  }

  return (
    <div className="jeopardy-keyword-search">
      <input
        type="text"
        placeholder="Search query"
        onChange={(e) => {
          if (e.target.value === "") {
            setSearchQuery(undefined);
            setActiveSearchQuery(undefined);
          } else {
            setSearchQuery(e.target.value);
          }
        }}
        value={searchQuery}
      />

      <React.Suspense fallback={<p>Loading num results...</p>}>
        {searchQuery ? <SearchResultsPreview query={searchQuery} /> : <p />}
      </React.Suspense>

      <button onClick={submitSearch}>Submit Search</button>

      <hr />

      <React.Suspense fallback="Loading results...">
        {activeSearchQuery ? <SearchResults query={activeSearchQuery} /> : null}
      </React.Suspense>
    </div>
  );
}
