import React from "react";
import "./App.css";

import JeopardyGamePage from "./pages/JeopardyGamePage";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import JeopardyStudyPage from "./pages/JeopardyStudyPage";
import JeopardyCoryatPage from "./pages/JeopardyCoryatPage";
import JeopardyCategorizationPage from "./pages/JeopardyCategorizationPage";
import TriviaGamePage from "./pages/TriviaGamePage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/jeopardy" element={<JeopardyGamePage />} />
        <Route path="/jeopardy/study" element={<JeopardyStudyPage />} />
        <Route path="/jeopardy/coryat" element={<JeopardyCoryatPage />} />
        <Route
          path="/jeopardy/categorization"
          element={<JeopardyCategorizationPage />}
        />

        <Route
          path="/trivia"
          element={<TriviaGamePage />}
        />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
