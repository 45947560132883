import React, { useState } from "react";
import TriviaGameMenu from "./TriviaGameMenu";

import { graphql, useMutation } from "react-relay";

import { TriviaGamePageValidateParamsMutation } from "./__generated__/TriviaGamePageValidateParamsMutation.graphql";
import TriviaGameBoard from "./TriviaGameBoard";

const CREATE_GAME = graphql`
  mutation TriviaGamePageCreateGameMutation(
    $input: CreateTriviaGameSessionInput!
  ) {
    createTriviaGameSession(input: $input) {
      success
    }
  }
`;

const VALIDATE_PARAMS = graphql`
  mutation TriviaGamePageValidateParamsMutation(
    $input: TriviaGameSessionValidateParamsInput!
  ) {
    triviaGameSessionCheckValidParams(input: $input) {
      success
    }
  }
`;

export default function TriviaGamePage() {
  const [currentStage, setCurrentStage] = useState(0);
  const [currentGameCode, setCurrentGameCode] = useState("");
  const [currentPlayerName, setCurrentPlayerName] = useState("");
  const [errorMessage, setErrorMessage] = useState(undefined);

  const [createGameMutation] = useMutation(CREATE_GAME);
  const [validateParams] =
    useMutation<TriviaGamePageValidateParamsMutation>(VALIDATE_PARAMS);

  function createGame(
    playerName: string,
    gameCode: string,
  ) {
    createGameMutation({
      variables: {
        input: {
          gameCode: gameCode,
          playerName: playerName,
        },
      },
      onCompleted: () => {
        setCurrentGameCode(gameCode);
        setCurrentPlayerName(playerName);
        setCurrentStage(currentStage + 1);
      },
    });
  }

  function joinGame(playerName: string, gameCode: string) {
    validateParams({
      variables: { input: { playerName: playerName, gameCode: gameCode } },
      onCompleted: (res, error) => {
        if (error) {
          // @ts-ignore
          setErrorMessage(error[0].message);

          return;
        }

        setErrorMessage(undefined);

        setCurrentPlayerName(playerName);
        setCurrentGameCode(gameCode);

        setCurrentStage(currentStage + 1);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }

  return (
    <div>
      {errorMessage ? (
        <p style={{ color: "red" }}>
          <strong>{errorMessage}</strong>
        </p>
      ) : null}
      {currentStage === 0 ? (
        <TriviaGameMenu createGame={createGame} joinGame={joinGame} />
      ) : null}
      {currentStage === 1 ? (
        <React.Suspense fallback="Loading trivia...">
          <TriviaGameBoard gameCode={currentGameCode} playerName={currentPlayerName} />
        </React.Suspense>
      ) : null}
    </div>
  );
}
