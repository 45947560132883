/**
 * @generated SignedSource<<f77b6a47bb3ca1ef70d83b1e4dd43ec1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TriviaGameBoardQuery$variables = {
  gameCode: string;
  playerName: string;
};
export type TriviaGameBoardQueryVariables = TriviaGameBoardQuery$variables;
export type TriviaGameBoardQuery$data = {
  readonly triviaGameSession: {
    readonly admin: {
      readonly username: string;
    };
    readonly triviagamesessionplayerSet: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly name: string;
          readonly active: boolean;
          readonly points: number;
          readonly admin: boolean;
        } | null;
      } | null>;
    };
    readonly currentClue: {
      readonly text: string | null;
      readonly episodeCategory: {
        readonly category: {
          readonly name: string;
        } | null;
        readonly comments: string | null;
      };
      readonly answer: {
        readonly correctResponse: string;
      } | null;
    } | null;
    readonly currentStage: number;
  } | null;
  readonly triviaGamePlayerInventory: {
    readonly item1: {
      readonly key: string;
      readonly displayName: string;
      readonly targetGlobal: boolean;
      readonly targetOther: boolean;
      readonly targetSelf: boolean;
      readonly stage: number;
    } | null;
    readonly item2: {
      readonly key: string;
      readonly displayName: string;
      readonly targetGlobal: boolean;
      readonly targetOther: boolean;
      readonly targetSelf: boolean;
      readonly stage: number;
    } | null;
  } | null;
  readonly whoAmI: {
    readonly username: string;
  } | null;
};
export type TriviaGameBoardQueryResponse = TriviaGameBoardQuery$data;
export type TriviaGameBoardQuery = {
  variables: TriviaGameBoardQueryVariables;
  response: TriviaGameBoardQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gameCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "playerName"
  }
],
v1 = {
  "kind": "Variable",
  "name": "gameCode",
  "variableName": "gameCode"
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "points",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comments",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correctResponse",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentStage",
  "storageKey": null
},
v13 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "playerName",
    "variableName": "playerName"
  }
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetGlobal",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetOther",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetSelf",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stage",
  "storageKey": null
},
v20 = [
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  (v19/*: any*/)
],
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v22 = [
  (v3/*: any*/),
  (v21/*: any*/)
],
v23 = [
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  (v19/*: any*/),
  (v21/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TriviaGameBoardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TriviaGameSessionType",
        "kind": "LinkedField",
        "name": "triviaGameSession",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "admin",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TriviaGameSessionPlayerTypeConnection",
            "kind": "LinkedField",
            "name": "triviagamesessionplayerSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TriviaGameSessionPlayerTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TriviaGameSessionPlayerType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyClueType",
            "kind": "LinkedField",
            "name": "currentClue",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyEpisodeCategoryType",
                "kind": "LinkedField",
                "name": "episodeCategory",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JeopardyCategoryType",
                    "kind": "LinkedField",
                    "name": "category",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyClueAnswerType",
                "kind": "LinkedField",
                "name": "answer",
                "plural": false,
                "selections": [
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "TriviaGameSessionPlayerInventoryType",
        "kind": "LinkedField",
        "name": "triviaGamePlayerInventory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TriviaGameItemType",
            "kind": "LinkedField",
            "name": "item1",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TriviaGameItemType",
            "kind": "LinkedField",
            "name": "item2",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "whoAmI",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TriviaGameBoardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TriviaGameSessionType",
        "kind": "LinkedField",
        "name": "triviaGameSession",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "admin",
            "plural": false,
            "selections": (v22/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TriviaGameSessionPlayerTypeConnection",
            "kind": "LinkedField",
            "name": "triviagamesessionplayerSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TriviaGameSessionPlayerTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TriviaGameSessionPlayerType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v21/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyClueType",
            "kind": "LinkedField",
            "name": "currentClue",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyEpisodeCategoryType",
                "kind": "LinkedField",
                "name": "episodeCategory",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JeopardyCategoryType",
                    "kind": "LinkedField",
                    "name": "category",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v21/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v21/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyClueAnswerType",
                "kind": "LinkedField",
                "name": "answer",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v21/*: any*/)
                ],
                "storageKey": null
              },
              (v21/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          (v21/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "TriviaGameSessionPlayerInventoryType",
        "kind": "LinkedField",
        "name": "triviaGamePlayerInventory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TriviaGameItemType",
            "kind": "LinkedField",
            "name": "item1",
            "plural": false,
            "selections": (v23/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TriviaGameItemType",
            "kind": "LinkedField",
            "name": "item2",
            "plural": false,
            "selections": (v23/*: any*/),
            "storageKey": null
          },
          (v21/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "whoAmI",
        "plural": false,
        "selections": (v22/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8a110046ab32eebeba55db3d4c0bcc6c",
    "id": null,
    "metadata": {},
    "name": "TriviaGameBoardQuery",
    "operationKind": "query",
    "text": "query TriviaGameBoardQuery(\n  $gameCode: String!\n  $playerName: String!\n) {\n  triviaGameSession(gameCode: $gameCode) {\n    admin {\n      username\n      id\n    }\n    triviagamesessionplayerSet {\n      edges {\n        node {\n          name\n          active\n          points\n          admin\n          id\n        }\n      }\n    }\n    currentClue {\n      text\n      episodeCategory {\n        category {\n          name\n          id\n        }\n        comments\n        id\n      }\n      answer {\n        correctResponse\n        id\n      }\n      id\n    }\n    currentStage\n    id\n  }\n  triviaGamePlayerInventory(gameCode: $gameCode, playerName: $playerName) {\n    item1 {\n      key\n      displayName\n      targetGlobal\n      targetOther\n      targetSelf\n      stage\n      id\n    }\n    item2 {\n      key\n      displayName\n      targetGlobal\n      targetOther\n      targetSelf\n      stage\n      id\n    }\n    id\n  }\n  whoAmI {\n    username\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5b0c98c452ab51a94e819b3dba1a85d7";

export default node;
