/**
 * @generated SignedSource<<cb0b6147b01d237f1368c3a31708f7c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CoryatHistoryQuery$variables = {};
export type CoryatHistoryQueryVariables = CoryatHistoryQuery$variables;
export type CoryatHistoryQuery$data = {
  readonly jeopardyCoryatAttempts: ReadonlyArray<{
    readonly id: string;
    readonly jeopardyEpisode: {
      readonly id: string;
      readonly episodeNumber: number;
      readonly airDate: any;
      readonly participants: string;
      readonly season: string;
    };
    readonly live: boolean;
    readonly coryatScore: number | null;
  } | null> | null;
};
export type CoryatHistoryQueryResponse = CoryatHistoryQuery$data;
export type CoryatHistoryQuery = {
  variables: CoryatHistoryQueryVariables;
  response: CoryatHistoryQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "completed",
        "value": true
      }
    ],
    "concreteType": "JeopardyCoryatAttemptType",
    "kind": "LinkedField",
    "name": "jeopardyCoryatAttempts",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "JeopardyEpisodeType",
        "kind": "LinkedField",
        "name": "jeopardyEpisode",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "episodeNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "airDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "participants",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "season",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "live",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "coryatScore",
        "storageKey": null
      }
    ],
    "storageKey": "jeopardyCoryatAttempts(completed:true)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CoryatHistoryQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CoryatHistoryQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e1e6a3d4a60eda30858f3e1ee63e31fc",
    "id": null,
    "metadata": {},
    "name": "CoryatHistoryQuery",
    "operationKind": "query",
    "text": "query CoryatHistoryQuery {\n  jeopardyCoryatAttempts(completed: true) {\n    id\n    jeopardyEpisode {\n      id\n      episodeNumber\n      airDate\n      participants\n      season\n    }\n    live\n    coryatScore\n  }\n}\n"
  }
};
})();

(node as any).hash = "67669ba27b4695f9106f03baef4568d8";

export default node;
