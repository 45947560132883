import React, { useEffect, useState } from "react";
import {
  graphql,
  useLazyLoadQuery,
  useMutation,
  useQueryLoader,
} from "react-relay";

import { CoryatEpisodeSelectQuery } from "./__generated__/CoryatEpisodeSelectQuery.graphql";
import { CoryatEpisodeSelectCreateAttemptMutation } from "./__generated__/CoryatEpisodeSelectCreateAttemptMutation.graphql";
import { CoryatEpisodeSelectDeleteAttemptMutation } from "./__generated__/CoryatEpisodeSelectDeleteAttemptMutation.graphql";
import { CoryatEpisodeSelectCompleteAttemptMutation } from "./__generated__/CoryatEpisodeSelectCompleteAttemptMutation.graphql";
import { CoryatEpisodeSelectRandomQuery } from "./__generated__/CoryatEpisodeSelectRandomQuery.graphql";
import { CoryatEpisodeSelectSyncEpisodesMutation } from "./__generated__/CoryatEpisodeSelectSyncEpisodesMutation.graphql";

const LATEST_EPISODES = graphql`
  query CoryatEpisodeSelectQuery {
    jeopardyLatestEpisodes {
      id
      episodeNumber
      airDate
      participants
      season
    }

    jeopardyCoryatAttempts(completed: false) {
      id

      jeopardyEpisode {
        id
        episodeNumber
        airDate
        participants
        season
      }

      live
    }
  }
`;

export const CREATE_ATTEMPT = graphql`
  mutation CoryatEpisodeSelectCreateAttemptMutation(
    $input: CreateJeopardyCoryatAttemptInput!
  ) {
    createJeopardyCoryatAttempt(input: $input) {
      attemptId
      episodeId
    }
  }
`;

const DELETE_ATTEMPT = graphql`
  mutation CoryatEpisodeSelectDeleteAttemptMutation(
    $input: DeleteCoryatAttemptInput!
  ) {
    deleteCoryatAttempt(input: $input) {
      success
    }
  }
`;

const COMPLETE_ATTEMPT = graphql`
  mutation CoryatEpisodeSelectCompleteAttemptMutation(
    $input: MarkCoryatAttemptCompletedInput!
  ) {
    markCoryatAttemptCompleted(input: $input) {
      success
    }
  }
`;

const SYNC_EPISODES = graphql`
  mutation CoryatEpisodeSelectSyncEpisodesMutation(
    $input: SyncJeopardyEpisodesInput!
  ) {
    syncJeopardyEpisodes(input: $input) {
      success
    }
  }
`;

export default function CoryatEpisodeSelect(props: {
  setSelectedEpisodeID: (episodeID: string) => void;
  setAttemptID: (attemptID: string) => void;
}) {
  const [createCoryatAttempt] =
    useMutation<CoryatEpisodeSelectCreateAttemptMutation>(CREATE_ATTEMPT);

  const [completeCoryatAttempt] =
    useMutation<CoryatEpisodeSelectCompleteAttemptMutation>(COMPLETE_ATTEMPT);

  const [deleteCoryatAttempt] =
    useMutation<CoryatEpisodeSelectDeleteAttemptMutation>(DELETE_ATTEMPT);

  const [syncEpisodes] =
    useMutation<CoryatEpisodeSelectSyncEpisodesMutation>(SYNC_EPISODES);

  const [fetchKey, setFetchKey] = useState(0);

  const data = useLazyLoadQuery<CoryatEpisodeSelectQuery>(
    LATEST_EPISODES,
    {},
    { fetchKey: fetchKey, fetchPolicy: "network-only" }
  );

  return (
    <div>
      <p>
        <button
          onClick={() => {
            const live = window.confirm("Is this attempt live?");

            createCoryatAttempt({
              variables: {
                input: { live: live },
              },
              onCompleted: (data) => {
                props.setAttemptID(
                  data.createJeopardyCoryatAttempt!.attemptId!
                );
                props.setSelectedEpisodeID(
                  data.createJeopardyCoryatAttempt!.episodeId!
                );
              },
            });
          }}
        >
          Random Episode
        </button>{" "}
        <button
          onClick={() => {
            syncEpisodes({ variables: { input: {} } });
          }}
        >
          Sync Episodes
        </button>
      </p>
      <ul>
        {data.jeopardyLatestEpisodes!.map((episode) => {
          return (
            <li>
              {episode!.episodeNumber} ({episode!.season}): {episode!.airDate} (
              {episode!.participants}){" "}
              <button
                onClick={() => {
                  const live = window.confirm("Is this attempt live?");

                  createCoryatAttempt({
                    variables: {
                      input: { episodeId: episode!.id, live: live },
                    },
                    onCompleted: (data) => {
                      props.setAttemptID(
                        data.createJeopardyCoryatAttempt!.attemptId!
                      );
                      props.setSelectedEpisodeID(episode!.id);
                    },
                  });
                }}
              >
                Select Episode
              </button>
            </li>
          );
        })}
      </ul>
      <hr />
      <h1>Incomplete Attempts</h1>
      <ul>
        <li>
          {data.jeopardyCoryatAttempts!.map((attempt) => {
            const episode = attempt!.jeopardyEpisode;

            return (
              <li>
                {episode!.episodeNumber} ({episode!.season}): {episode!.airDate}{" "}
                ({episode!.participants}){" "}
                <button
                  onClick={() => {
                    props.setAttemptID(attempt!.id);
                    props.setSelectedEpisodeID(episode!.id);
                  }}
                >
                  Continue Attempt
                </button>{" "}
                <button
                  onClick={() => {
                    completeCoryatAttempt({
                      variables: {
                        input: { attemptId: attempt!.id },
                      },
                      onCompleted: () => {
                        setFetchKey((prevState) => prevState + 1);
                      },
                    });
                  }}
                >
                  Complete Attempt
                </button>{" "}
                <button
                  onClick={() => {
                    if (window.confirm("Are you sure you want to delete?"))
                      deleteCoryatAttempt({
                        variables: {
                          input: { attemptId: attempt!.id },
                        },
                        onCompleted: () => {
                          setFetchKey((prevState) => prevState + 1);
                        },
                      });
                  }}
                >
                  Delete Attempt
                </button>
              </li>
            );
          })}
        </li>
      </ul>
    </div>
  );
}
