import React, { useState } from "react";
import SelectEpisode from "./SelectEpisode";

export default function JeopardyGameMenu(props: {
  createGame: (
    playerName: string,
    gameCode: string,
    episodeID: string,
    doubleJeopardy: boolean
  ) => void;
  joinGame: (playerName: string, gameCode: string) => void;
}) {
  const [playerName, setPlayerName]: [string | undefined, any] =
    useState(undefined);
  const [gameCode, setGameCode]: [string | undefined, any] =
    useState(undefined);

  const [creatingGame, setCreatingGame] = useState(false);

  function createGameWithEpisode(episodeID: string, doubleJeopardy: boolean) {
    if (playerName && gameCode) {
      props.createGame(playerName, gameCode, episodeID, doubleJeopardy);
    } else {
      alert("Must include a player name and game code");
    }
  }

  return (
    <div>
      <label>
        Game Code:{" "}
        <input
          type="text"
          value={gameCode}
          onChange={(e) => {
            setGameCode(e.target.value);
          }}
        />
      </label>
      <br />
      <label>
        Player Name:{" "}
        <input
          type="text"
          value={playerName}
          onChange={(e) => {
            setPlayerName(e.target.value);
          }}
        />
      </label>
      <br />
      <button
        onClick={(e) => {
          e.preventDefault();

          if (playerName && gameCode) {
            props.joinGame(playerName, gameCode);
          } else {
            alert("Must include a player name and game code.");
          }
        }}
      >
        Join Game
      </button>
      <hr />
      <label>
        Game Code:{" "}
        <input
          type="text"
          value={gameCode}
          onChange={(e) => {
            setGameCode(e.target.value);
          }}
        />
      </label>
      <br />
      <label>
        Player Name:{" "}
        <input
          type="text"
          value={playerName}
          onChange={(e) => {
            setPlayerName(e.target.value);
          }}
        />
      </label>
      <br />
      <button
        onClick={(e) => {
          e.preventDefault();
          setCreatingGame(true);
        }}
      >
        Create Game
      </button>

      {creatingGame ? (
        <>
          <br />

          <React.Suspense fallback="Loading episode options...">
            <SelectEpisode createGameWithEpisode={createGameWithEpisode} />
          </React.Suspense>
        </>
      ) : null}
    </div>
  );
}
