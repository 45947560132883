import React, { useState } from "react";

export default function JeopardyGameMenu(props: {
  createGame: (playerName: string, gameCode: string) => void;
  joinGame: (playerName: string, gameCode: string) => void;
}) {
  const [playerName, setPlayerName]: [string | undefined, any] =
    useState(undefined);
  const [gameCode, setGameCode]: [string | undefined, any] =
    useState(undefined);

  return (
    <div>
      <label>
        Game Code:{" "}
        <input
          type="text"
          value={gameCode}
          onChange={(e) => {
            setGameCode(e.target.value);
          }}
        />
      </label>
      <br />
      <label>
        Player Name:{" "}
        <input
          type="text"
          value={playerName}
          onChange={(e) => {
            setPlayerName(e.target.value);
          }}
        />
      </label>
      <br />
      <button
        onClick={(e) => {
          e.preventDefault();

          if (playerName && gameCode) {
            props.joinGame(playerName, gameCode);
          } else {
            alert("Must include a player name and game code.");
          }
        }}
      >
        Join Game
      </button>
      <hr />
      <label>
        Game Code:{" "}
        <input
          type="text"
          value={gameCode}
          onChange={(e) => {
            setGameCode(e.target.value);
          }}
        />
      </label>
      <br />
      <label>
        Player Name:{" "}
        <input
          type="text"
          value={playerName}
          onChange={(e) => {
            setPlayerName(e.target.value);
          }}
        />
      </label>
      <br />
      <button
        onClick={(e) => {
          e.preventDefault();
          if (playerName && gameCode) {
            props.createGame(playerName, gameCode);
          } else {
            alert("Must include a player name and game code");
          }
        }}
      >
        Create Game
      </button>
    </div>
  );
}
