import React, { useState } from "react";

import { graphql, useLazyLoadQuery } from "react-relay";

import { JeopardyCategoriesSelectQuery } from "./__generated__/JeopardyCategoriesSelectQuery.graphql";
import "./JeopardyCategoriesSelect.css";

const CATEGORIES = graphql`
  query JeopardyCategoriesSelectQuery {
    jeopardyMetaCategories {
      id

      parent {
        id
      }

      name
    }
  }
`;

export default function JeopardyCategoriesSelect(props: {
  selectedCategoryIDs: string[];
  updateCategoryIDSelection: (categoryID: string) => void;
}) {
  const [fetchKey, setFetchKey] = useState(0);

  const data = useLazyLoadQuery<JeopardyCategoriesSelectQuery>(
    CATEGORIES,
    {},
    { fetchKey, fetchPolicy: "network-only" }
  );

  const categories = data!.jeopardyMetaCategories!;

  const parentCategories = categories.filter((cat) => cat!.parent === null);

  // @ts-ignore
  function categoryElement(
    metaCategory: {
      readonly id: string;
      readonly parent: { readonly id: string } | null;
      readonly name: string;
    } | null,
    depth: number
  ) {
    return (
      <>
        <div
          className={`jeopardy-category-select-option jeopardy-category-select-option-${
            props.selectedCategoryIDs.includes(metaCategory!.id)
              ? "selected"
              : "unselected"
          } noselect`}
          style={{ marginLeft: `${40 * depth}px` }}
          onClick={() => {
            props.updateCategoryIDSelection(metaCategory!.id);
          }}
        >
          {metaCategory!.name}
        </div>
        {categories
          .filter((category) => category!.parent?.id === metaCategory!.id)
          .map((category) => {
            return categoryElement(category, depth + 1);
          })}
      </>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <br />
      <div>
        <button onClick={() => setFetchKey((prev) => prev + 1)}>
          Reload Categories
        </button>
      </div>
      <div className="jeopardy-category-select-container">
        {parentCategories.map((metaCategory) => {
          return (
            <div className="jeopardy-category-select-group">
              {categoryElement(metaCategory, 0)}
            </div>
          );
        })}
      </div>
    </div>
  );
}
