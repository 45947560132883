/**
 * @generated SignedSource<<8c062edf60240aabdc36c8c3b4be053c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CoryatCluesQuery$variables = {
  episodeID: string;
  attemptID: string;
};
export type CoryatCluesQueryVariables = CoryatCluesQuery$variables;
export type CoryatCluesQuery$data = {
  readonly jeopardyCluesForEpisode: ReadonlyArray<{
    readonly id: string;
    readonly episodeCategory: {
      readonly category: {
        readonly name: string;
      } | null;
      readonly roundType: {
        readonly title: string;
      };
    };
    readonly text: string | null;
    readonly value: number | null;
    readonly answer: {
      readonly correctResponse: string;
    } | null;
  } | null> | null;
  readonly jeopardyCoryatAttempt: {
    readonly jeopardycluecoryatresponseSet: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly clue: {
            readonly id: string;
          };
        } | null;
      } | null>;
    };
    readonly live: boolean;
  } | null;
};
export type CoryatCluesQueryResponse = CoryatCluesQuery$data;
export type CoryatCluesQuery = {
  variables: CoryatCluesQueryVariables;
  response: CoryatCluesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attemptID"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "episodeID"
},
v2 = [
  {
    "kind": "Variable",
    "name": "episodeId",
    "variableName": "episodeID"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correctResponse",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "attemptId",
    "variableName": "attemptID"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "JeopardyClueType",
  "kind": "LinkedField",
  "name": "clue",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "live",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CoryatCluesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "JeopardyClueType",
        "kind": "LinkedField",
        "name": "jeopardyCluesForEpisode",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyEpisodeCategoryType",
            "kind": "LinkedField",
            "name": "episodeCategory",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyCategoryType",
                "kind": "LinkedField",
                "name": "category",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyRoundTypeType",
                "kind": "LinkedField",
                "name": "roundType",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyClueAnswerType",
            "kind": "LinkedField",
            "name": "answer",
            "plural": false,
            "selections": [
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "JeopardyCoryatAttemptType",
        "kind": "LinkedField",
        "name": "jeopardyCoryatAttempt",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyClueCoryatResponseTypeConnection",
            "kind": "LinkedField",
            "name": "jeopardycluecoryatresponseSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyClueCoryatResponseTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JeopardyClueCoryatResponseType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CoryatCluesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "JeopardyClueType",
        "kind": "LinkedField",
        "name": "jeopardyCluesForEpisode",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyEpisodeCategoryType",
            "kind": "LinkedField",
            "name": "episodeCategory",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyCategoryType",
                "kind": "LinkedField",
                "name": "category",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyRoundTypeType",
                "kind": "LinkedField",
                "name": "roundType",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyClueAnswerType",
            "kind": "LinkedField",
            "name": "answer",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "JeopardyCoryatAttemptType",
        "kind": "LinkedField",
        "name": "jeopardyCoryatAttempt",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyClueCoryatResponseTypeConnection",
            "kind": "LinkedField",
            "name": "jeopardycluecoryatresponseSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyClueCoryatResponseTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JeopardyClueCoryatResponseType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c36401000e9c0a177572a06a1a3c8e03",
    "id": null,
    "metadata": {},
    "name": "CoryatCluesQuery",
    "operationKind": "query",
    "text": "query CoryatCluesQuery(\n  $episodeID: ID!\n  $attemptID: ID!\n) {\n  jeopardyCluesForEpisode(episodeId: $episodeID) {\n    id\n    episodeCategory {\n      category {\n        name\n        id\n      }\n      roundType {\n        title\n        id\n      }\n      id\n    }\n    text\n    value\n    answer {\n      correctResponse\n      id\n    }\n  }\n  jeopardyCoryatAttempt(attemptId: $attemptID) {\n    jeopardycluecoryatresponseSet {\n      edges {\n        node {\n          clue {\n            id\n          }\n          id\n        }\n      }\n    }\n    live\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1eae4be653f1590c9cc4f5d57d5a2bfd";

export default node;
