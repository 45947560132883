import React, { useState, useEffect } from "react";
import "./ClueDisplay.css";
import ClueTimer from "./ClueTimer";
import ClueBuzzer from "./ClueBuzzer";
import PointAllocator from "./PointAllocator";

import DDImg from "../../../../assets/images/JEP38_JBuzz_1110_OOS_DailyDoublesFullClip Featured Image_1600x900.jpg";

export default function ClueDisplay(props: {
  clue: {
    node: {
      id: string;
      text: string;
      answer: {
        correctResponse: string;
      };
      value: number;
      dailyDouble: boolean;
    };
  };
  showAnswer: () => void;
  displayAnswer: boolean;
  exitClue: any;
  isAdmin: boolean;
  submitBuzz: (buzzTime: number | null) => void;
  playerNames: string[] | undefined;
  currentlySelectingPlayer: string;
  maxDailyDoubleWager: number;
  currentRound: string;
  showDailyDoubleClue: boolean;
  sendJSONMessage: (eventType: string, message: string) => void;
}) {
  const [buzzerActive, setBuzzerActive] = useState(false);
  const [buzzerPermDisabled, setBuzzerPermDisabled] = useState(false);
  const [playerBuzzed, setPlayerBuzzed] = useState(false);

  const [clueViewStartTime, setClueViewStartTime]: [number | undefined, any] =
    useState(undefined);
  const [dailyDoubleWagerValue, setDailyDoubleWagerValue] = useState(NaN);
  const [clueValue, setClueValue] = useState(props.clue.node.value);

  const [showDailyDouble, setShowDailyDouble] = useState(
    props.clue.node.dailyDouble
  );

  const maxOnBoard = props.currentRound === "Jeopardy!" ? 1000 : 2000;
  const maxWager = Math.max(props.maxDailyDoubleWager, maxOnBoard);

  const numWordsInClue = props.clue.node.text.match(/(\w+)/g)!.length;

  const clueViewStartTimeRef = React.useRef(clueViewStartTime);

  const setClueViewStartTimeRef = (data: number | undefined) => {
    // @ts-ignore
    clueViewStartTimeRef.current = data;
  };

  useEffect(() => {
    setClueViewStartTimeRef(clueViewStartTime);
  }, [clueViewStartTime]);

  useEffect(() => {
    if (props.clue.node.dailyDouble) {
      const dailyDoubleAudio = new Audio(
        "https://www.myinstants.com/media/sounds/jeopardy-daily-double-sound-effect-from-youtube.mp3"
      );

      dailyDoubleAudio.volume = 0.2;

      dailyDoubleAudio.play().then();
    }
  }, []);

  function activateBuzzer() {
    setClueViewStartTime(Date.now());

    setBuzzerActive(true);
  }

  function deactivateBuzzer(sendNoBuzzMessage: boolean) {
    setBuzzerActive(false);

    setBuzzerPermDisabled(true);

    if (sendNoBuzzMessage) {
      props.submitBuzz(null);
    }
  }

  function buzzIn() {
    setBuzzerActive(false);
    setPlayerBuzzed(true);

    if (clueViewStartTimeRef.current) {
      props.submitBuzz(Date.now() - clueViewStartTimeRef.current);
    }
  }

  function allocatePoints(pointAllocations: { [x: string]: number }) {
    props.exitClue(props.clue.node.id, pointAllocations);
  }

  return showDailyDouble && !props.showDailyDoubleClue ? (
    <div className="clue-display-daily-double">
      <img src={DDImg} />
      {props.isAdmin ? (
        <div className="clue-display-daily-double-wager">
          <label>
            <input
              type="number"
              onChange={(e) => {
                setDailyDoubleWagerValue(parseInt(e.target.value));
              }}
              value={dailyDoubleWagerValue}
              min={5}
              max={maxWager}
            />{" "}
            <button
              onClick={(e) => {
                setClueValue(dailyDoubleWagerValue);
                setShowDailyDouble(false);

                props.sendJSONMessage("SHOW_DAILY_DOUBLE_CLUE", "");
              }}
              disabled={
                dailyDoubleWagerValue < 5 ||
                dailyDoubleWagerValue > maxWager ||
                isNaN(dailyDoubleWagerValue)
              }
            >
              Submit Wager
            </button>{" "}
            (Must be between 5 and {maxWager})
          </label>
        </div>
      ) : null}
    </div>
  ) : (
    <div className="clue-display-container">
      <>
        <p
          dangerouslySetInnerHTML={{
            __html: !props.displayAnswer
              ? props.clue.node.text
              : props.clue.node.answer.correctResponse,
          }}
        />
        {props.isAdmin && !props.displayAnswer ? (
          <button onClick={() => props.showAnswer()}>Show Answer</button>
        ) : null}
        <br />
        {!props.displayAnswer ? (
          !props.clue.node.dailyDouble ? (
            <>
              <ClueBuzzer
                buzzerActive={clueViewStartTime !== undefined}
                buzzIn={buzzIn}
                buzzerPermDisabled={buzzerPermDisabled}
              />
              <br />
              <ClueTimer
                numWords={numWordsInClue}
                activateBuzzer={activateBuzzer}
                deactivateBuzzer={deactivateBuzzer}
                playerBuzzed={playerBuzzed}
              />
            </>
          ) : null
        ) : props.isAdmin ? (
          <PointAllocator
            players={props.playerNames}
            clueValue={clueValue}
            dailyDoublePlayer={
              props.clue.node.dailyDouble
                ? props.currentlySelectingPlayer
                : undefined
            }
            allocatePoints={allocatePoints}
          />
        ) : null}
      </>
    </div>
  );
}
