import React, { useState, useEffect } from "react";

import { graphql, useLazyLoadQuery, useMutation } from "react-relay";

import { JeopardyCategorizationCluesListQuery } from "./__generated__/JeopardyCategorizationCluesListQuery.graphql";
import { JeopardyCategorizationCluesListSkipCategoryMutation } from "./__generated__/JeopardyCategorizationCluesListSkipCategoryMutation.graphql";

import "./JeopardyCategorizationCluesList.css";

const GET_CLUES = graphql`
  query JeopardyCategorizationCluesListQuery($searchText: String) {
    jeopardyCluesForMetaCategorization(searchText: $searchText) {
      id

      text
      answer {
        id
        correctResponse
      }

      episodeCategory {
        category {
          id
          name
        }
      }
    }
  }
`;

const SKIP_CATEGORY = graphql`
  mutation JeopardyCategorizationCluesListSkipCategoryMutation(
    $input: SkipCategoryMetaCategorizationInput!
  ) {
    skipCategoryMetaCategorization(input: $input) {
      success
    }
  }
`;

export default function JeopardyCategorizationCluesList(props: {
  selectedClueIDs: string[];
  updateClueIDSelections: (clueID: string) => void;
  submitCategorizations: (
    clueIds: string[],
    propagateClueIDs: string[]
  ) => void;
  clueFetchKey: number;
  setClueFetchKey: (prevState: any) => any;
  lastCategorizationAmount: number | undefined;
}) {
  const [potentialSearchText, setPotentialSearchText] = useState(undefined);
  const [searchText, setSearchText] = useState(undefined);

  const data = useLazyLoadQuery<JeopardyCategorizationCluesListQuery>(
    GET_CLUES,
    {
      searchText,
    },
    { fetchKey: props.clueFetchKey, fetchPolicy: "network-only" }
  );

  const [skipCategoryMutation] =
    useMutation<JeopardyCategorizationCluesListSkipCategoryMutation>(
      SKIP_CATEGORY
    );

  const [selectedClueIDs, setSelectedClueIDs] = useState(
    data!.jeopardyCluesForMetaCategorization!.map((clue) => clue!.id)
  );

  const [propagateClueIDs, setPropagateClueIDs]: [string[], any] = useState([]);

  useEffect(() => {
    setSelectedClueIDs(
      data!.jeopardyCluesForMetaCategorization!.map((clue) => clue!.id)
    );

    setPropagateClueIDs([]);
  }, [data]);

  function skipCategory() {
    const categories = data!.jeopardyCluesForMetaCategorization!.map(
      (clue) => clue!.episodeCategory!.category!.id
    );

    const uniqueCategories = categories.filter(
      (item, i, ar) => ar.indexOf(item) === i
    );

    if (uniqueCategories.length !== 1) {
      props.setClueFetchKey((prevState: number) => prevState + 1);
      // @ts-ignore
      setPotentialSearchText("");
      setSearchText(undefined);
    } else {
      skipCategoryMutation({
        variables: { input: { categoryId: uniqueCategories[0] } },
        onCompleted: () => {
          props.setClueFetchKey((prevState: number) => prevState + 1);
        },
      });
    }
  }

  function updateClueIDSelections(clueID: string) {
    const idIndex = selectedClueIDs.indexOf(clueID);

    if (idIndex > -1) {
      setSelectedClueIDs((prevState: string[]) => {
        prevState.splice(idIndex, 1);
        return [...prevState];
      });
    } else {
      setSelectedClueIDs((prevState: string[]) => {
        return [...prevState, clueID];
      });
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      {props.lastCategorizationAmount ? (
        <p style={{ textAlign: "center" }}>
          Categorized {props.lastCategorizationAmount} clues.
        </p>
      ) : null}
      <br />
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div style={{ textAlign: "center" }}>
          <button onClick={skipCategory}>Skip Category</button>
          <br />
          <br />
          <button
            onClick={() =>
              props.submitCategorizations(selectedClueIDs, propagateClueIDs)
            }
          >
            Submit Categorization
          </button>
        </div>
        <div style={{ textAlign: "center", flex: "0 1 auto" }}>
          <button
            onClick={() => {
              setPropagateClueIDs(
                data.jeopardyCluesForMetaCategorization!.map((clue) => clue!.id)
              );
            }}
          >
            Propagate to All Answers
          </button>
          <br />
          <br />
          <button onClick={() => setPropagateClueIDs([])}>
            Clear Propagations
          </button>
        </div>
      </div>
      <br />
      <div className="jeopardy-categorization-clues-list-search-container">
        <input
          type="text"
          value={potentialSearchText}
          onChange={(e) => {
            // @ts-ignore
            setPotentialSearchText(e.target.value);
          }}
        />
        <button onClick={() => setSearchText(potentialSearchText)}>
          Search
        </button>
      </div>
      <br />
      <div
        className="jeopardy-categorization-clues-list-container"
        style={{ flex: "1 1 auto" }}
      >
        {data.jeopardyCluesForMetaCategorization!.map((clue) => {
          return (
            <div>
              <div
                className={`jeopardy-categorization-clues-list-clue jeopardy-categorization-clues-list-clue-${
                  selectedClueIDs.includes(clue!.id) ? "selected" : "unselected"
                } noselect`}
                onClick={() => updateClueIDSelections(clue!.id)}
              >
                <div>
                  <p style={{ textDecoration: "underline" }}>
                    {clue!.episodeCategory.category?.name}
                  </p>
                  <p>{clue!.text}</p>
                </div>
                <div>
                  <strong>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: clue!.answer
                          ? clue!.answer?.correctResponse
                          : "",
                      }}
                    />
                  </strong>
                </div>
              </div>
              <div>
                <label>Propagate above to answer?</label>
                <input
                  type="checkbox"
                  checked={propagateClueIDs.includes(clue!.id)}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      const idIndex = propagateClueIDs.indexOf(clue!.id);

                      if (idIndex > -1) {
                        setPropagateClueIDs((prevState: string[]) => {
                          prevState.splice(idIndex, 1);
                          return [...prevState];
                        });
                      }
                    } else {
                      setPropagateClueIDs((prevState: string[]) => {
                        return [...prevState, clue!.id];
                      });
                    }
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <br />
    </div>
  );
}
