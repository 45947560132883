/**
 * @generated SignedSource<<b7b05d6b9452c3d85264e4dbfd2f273a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SelectEpisodeQuery$variables = {};
export type SelectEpisodeQueryVariables = SelectEpisodeQuery$variables;
export type SelectEpisodeQuery$data = {
  readonly jeopardyEpisodeOptions: ReadonlyArray<{
    readonly id: string;
    readonly airDate: any;
    readonly finalJeopardyCategory: {
      readonly id: string;
      readonly comments: string | null;
      readonly category: {
        readonly name: string;
      } | null;
    } | null;
    readonly jeopardyepisodecategorySet: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly comments: string | null;
          readonly roundType: {
            readonly title: string;
          };
          readonly category: {
            readonly name: string;
          } | null;
        } | null;
      } | null>;
    };
    readonly metaCategories: ReadonlyArray<{
      readonly name: string;
      readonly parent: {
        readonly name: string;
      } | null;
    } | null> | null;
  } | null> | null;
};
export type SelectEpisodeQueryResponse = SelectEpisodeQuery$data;
export type SelectEpisodeQuery = {
  variables: SelectEpisodeQueryVariables;
  response: SelectEpisodeQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "airDate",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comments",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "JeopardyCategoryType",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = [
  (v3/*: any*/),
  (v0/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "JeopardyCategoryType",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectEpisodeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JeopardyEpisodeType",
        "kind": "LinkedField",
        "name": "jeopardyEpisodeOptions",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyEpisodeCategoryType",
            "kind": "LinkedField",
            "name": "finalJeopardyCategory",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyEpisodeCategoryTypeConnection",
            "kind": "LinkedField",
            "name": "jeopardyepisodecategorySet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyEpisodeCategoryTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JeopardyEpisodeCategoryType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JeopardyRoundTypeType",
                        "kind": "LinkedField",
                        "name": "roundType",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyMetaCategoryType",
            "kind": "LinkedField",
            "name": "metaCategories",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyMetaCategoryType",
                "kind": "LinkedField",
                "name": "parent",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SelectEpisodeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JeopardyEpisodeType",
        "kind": "LinkedField",
        "name": "jeopardyEpisodeOptions",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyEpisodeCategoryType",
            "kind": "LinkedField",
            "name": "finalJeopardyCategory",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyEpisodeCategoryTypeConnection",
            "kind": "LinkedField",
            "name": "jeopardyepisodecategorySet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyEpisodeCategoryTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JeopardyEpisodeCategoryType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JeopardyRoundTypeType",
                        "kind": "LinkedField",
                        "name": "roundType",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JeopardyMetaCategoryType",
            "kind": "LinkedField",
            "name": "metaCategories",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "JeopardyMetaCategoryType",
                "kind": "LinkedField",
                "name": "parent",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1edcd73f7b133ee878138e916c44982b",
    "id": null,
    "metadata": {},
    "name": "SelectEpisodeQuery",
    "operationKind": "query",
    "text": "query SelectEpisodeQuery {\n  jeopardyEpisodeOptions {\n    id\n    airDate\n    finalJeopardyCategory {\n      id\n      comments\n      category {\n        name\n        id\n      }\n    }\n    jeopardyepisodecategorySet {\n      edges {\n        node {\n          id\n          comments\n          roundType {\n            title\n            id\n          }\n          category {\n            name\n            id\n          }\n        }\n      }\n    }\n    metaCategories {\n      name\n      parent {\n        name\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "70d2526c8aa67617124a1301fd79ddf8";

export default node;
