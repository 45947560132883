import React from "react";

import { graphql, useLazyLoadQuery } from "react-relay";

import { SearchResultsQuery } from "./__generated__/SearchResultsQuery.graphql";

import "./SearchResults.css";

const SEARCH_QUERY = graphql`
  query SearchResultsQuery($query: String!) {
    jeopardySearch(text: $query) {
      id

      text

      answer {
        correctResponse
      }

      dailyDouble

      episodeCategory {
        category {
          name
        }
      }
    }
  }
`;

export default function SearchResults({ query }: { query: string }) {
  const data = useLazyLoadQuery<SearchResultsQuery>(
    SEARCH_QUERY,
    { query },
    {}
  );

  return (
    <ul className="jeopardy-search-results">
      {data.jeopardySearch?.map((clue) => {
        return (
          <li className="jeopardy-search-result">
            <div>
              <p>
                <strong>{clue?.text}</strong>
              </p>
              <p>{clue?.answer?.correctResponse}</p>
              <hr />
            </div>
          </li>
        );
      })}
    </ul>
  );
}
